import React, { useEffect } from "react";
import './header.css'
import { useDispatch, useSelector } from "react-redux";
import { setHasAppBar } from "../../store/slices/uiSlice";
import { useLocation, useNavigate } from "react-router-dom";
import TheDrawer from "./drawer";

const ActualHeader = ()=>{
    
    const dispatch = useDispatch()
    const nav = useNavigate()
    const location = useLocation()
    const user = useSelector((s)=> s.user)
    const appBar = useSelector((s)=>s.ui.hasAppBar)
    const doClick = ()=>{
        dispatch(setHasAppBar(!appBar))
    }

    useEffect(()=>{
        
    }, [])
    return(
        <div className="nav flex x-center space-between g10">
            <h1 className="brand" onClick={async()=>{
                nav('/')
            }}>
                JUST DIGITAL DIARY
                {
                    location.pathname === '/admin-panel' && (user.role === "Master Admin" || user.role === "editor") ? 
                    <h2 className="subBrand">Admin Panel</h2>
                    : null
                }
            </h1>
            {/* <div className="links">
                
            </div> */}
            
            <div onClick={doClick} className={appBar ? "menu active" : "menu"}>
                <div className="bar bar1"></div>
                <div className="bar bar2"></div>
                <div className="bar bar3"></div>
            </div>

            <div id="drawer" className={appBar ? "drawer active" : "drawer"}>
                <TheDrawer  />
            </div>
            
            <div onClick={doClick} className={appBar ? "background active" : "background"}>
                
            </div>
        </div>
    )
}

export default ActualHeader