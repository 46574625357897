import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    email: null,
    code: null,
    username: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        SET_EMAIL: (state, action) => {
            state.email = action.payload
        },
        SET_CODE: (state, action) => {
            state.code = action.payload
        },
        SET_USERNAME: (state, action) => {
            state.username = action.payload
        },
        RESET_AUTH: (state) => {
            return initialState
        },
    },
});

export const { SET_EMAIL, SET_CODE, RESET_AUTH } = authSlice.actions;
export default authSlice.reducer;