import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { displayToastWithTimeout, setIsLoading } from '../../store/slices/uiSlice';
import { API } from '../../constants/constant';
import './style.css'
import { fetchDesiredData } from '../../store/slices/dataSlice';
const FacultyMembers = ()=>{
    const { dept_id } = useParams();
    const nav = useNavigate()
    const [dept, setDept] = useState(null)
    const dispatch = useDispatch()
    const user = useSelector((s)=>s.user)
    const [sortedMembers, setSortedMembers] = useState([]);
    const apiUrl = '/api/get/all-departments'
    const apiUrl2 = '/api/get/faculty-members/' + dept_id
    const data = useSelector((state)=>state.dataSliceState.data[apiUrl])
    const data2 = useSelector((state)=>state.dataSliceState.data[apiUrl2])

  const designationRank = {
    'Chairman': 1,
    'Professor': 2,
    'Associate Professor': 3,
    'Assistant Professor': 4,
    'Lecturer': 5,
    'Instructor': 6,
    'Guest Teacher': 7,
    'Guest Lecturer': 8,
    'Section Officer (Grade-9)': 9,
    'Section Officer (Grade-10)': 10,
  };

  const fetchFacultyMemberData = async () => {

    if (!data2) {
      dispatch(fetchDesiredData({ apiUrl: apiUrl2, token: user.token }));
    } else {
      letsSort(data2.facultyMembers)
    }
  };



  const letsSort = async(data)=>{
    const theArray = [...data]
    const sorted = theArray.sort((a, b) => {
      const designationA = a.departments[0]?.designation || '';
      const designationB = b.departments[0]?.designation || '';

      const rankA = designationRank[designationA] || Infinity;
      const rankB = designationRank[designationB] || Infinity;

      return rankA - rankB;
    });

    setSortedMembers(sorted);
  }

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index === selectedItemIndex ? null : index);
  };

  useEffect(() => {
    if (!data) {
      dispatch(fetchDesiredData({ apiUrl, token: user.token }));
    } else {
      const hasDept = data.departments.some((m)=>{
        return m.dept_id === dept_id
      })

      hasDept ? fetchFacultyMemberData() : nav('/')
      const fac = data.departments.filter((m)=>{
        return m.dept_id === dept_id
      })
      setDept(fac[0])
    }
    dispatch(setIsLoading(false))
    const timeoutId = setTimeout(()=>dispatch(()=>fetchDesiredData({ apiUrl, token: user.token })), 5000);
    return () => clearTimeout(timeoutId);
  }, [apiUrl, data, data2, apiUrl2, user.token]);

  return (
    <div>
      <h2 className='subTitle'>Department Of</h2>
      <p className='extraTitle dept-text'>{dept && dept.name}</p>
      <div className="departments">
        {
            sortedMembers.length > 0 ? sortedMembers.map((m, i)=>{
        
                return <OtherMember key={i} index={i+1} handleItemClick={handleItemClick} data={m} isSelected={(i+1) === selectedItemIndex}/>
            }) : <h2 className='subTitle'>Sorry, Try Again!</h2>
        }
      </div>
    </div>
  );
}

export default FacultyMembers




export const OtherMember = ({data, isSelected, handleItemClick, index})=>{

  const [phone, setPhone]= useState(null)
  const [designation, setDesignation] = useState(null)
  const [pre, setPre] = useState(null)
  const [rooms, setRooms] = useState(null)

  const stopThePropagation = (e) => {
    e.stopPropagation();
  };
  useEffect(() => {
    setPhone(data.phone.split(','))
    const preee = data.departments && data.departments.length > 0 ? data.departments[0].present : data.offices[0].present
    setPre(preee)

    const des = Array.isArray(data.departments) && data.departments.length > 0 ? data.departments.map((m)=>m.designation).join(', ') : data.offices.map((m)=>m.designation).join(', ')
    const rom = Array.isArray(data.departments) && data.departments.length > 0 ? data.departments.map((m) => m.room_no) : data.offices.map((m) => m.room_no)
    setDesignation(des)
    setRooms(rom)

  }, [isSelected, data]);

    return data && (
        <div className={`facultymember` + (isSelected ? " active" : " a")} onClick={()=>handleItemClick(index)}>
            <div className='image'>
                
                <div className="cont">
                  {
                    data.profile ? <img src={`${API}/uploads/${data.profile}`} alt={data.name} /> : <img src={process.env.PUBLIC_URL + '/assets/new/profile.svg'} alt={data.name} />
                  }
                </div>
            </div>
            <div className='fg'>
                <h3 className='name'>{data.name}</h3>
                <p className="des">{designation} {pre?"":"(On Study Leave)"}</p>
                <div className="transitionHeight">
                  <div className="flex column g20 afterActive">
                    <div className={'achievement'}>{data.achievement}</div>
                    <div className="flex g20 roomPhone wrap">
                
                      {
                        Array.isArray(rooms) && rooms.length > 0 ? rooms.map((p, i) => {
                          return (
                            <p key={i} className="room">PBX No <br />{p ? p : "N/A"}</p>
                          )
                        }) : null
                      }
                        {
                          Array.isArray(phone) && phone.length > 0 ? phone.map((p, i)=>{
                            return (
                              <a key={i} onClick={stopThePropagation} href={`tel:${p}`} className="phone flex x-center g10">
                                <img src={process.env.PUBLIC_URL + '/assets/phone.svg'} alt="Phone" />
                                <p>{p}</p>
                              </a>
                            )
                          }) : <a onClick={stopThePropagation} href={`tel:${phone}`} className="phone flex x-center g10">
                                <img src={process.env.PUBLIC_URL + '/assets/phone.svg'} alt="Phone" />
                                <p>{phone}</p>
                              </a>
                        }
                      
                    </div>
                    <div className="f1">
                    <a onClick={stopThePropagation} href={`mailto:${data.email}`} className="email flex x-center g10">
                        <img src={process.env.PUBLIC_URL + '/assets/mail.svg'} alt="Mail" />
                        <p className='break-word'>{data.email}</p>
                      </a>
                      {
                      data.additional_email !== null && data.additional_email !== 'null' ? <a onClick={stopThePropagation} href={`mailto:${data.additional_email}`} className="email flex x-center g10">
                            <img src={process.env.PUBLIC_URL + '/assets/mail.svg'} alt="Mail" />
                            <p className='break-word'>{data.additional_email}</p>
                          </a> : null
                      }
                      
                    </div>
                  </div>
                </div>
            </div>
        </div>
    )
}