import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  name: null,
  username: null,
  designations: null,
  email: null,
  role: null,
  type: null,
  isChairman: null,
  department: null,
  dept_id: null,
  sub_office_id: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      const { token, role } = action.payload;
      return { ...state, token, role };
    },
    setInfo: (state, action) => {
      const { type, isChairman, dept_id, sub_office_id, name, username, email, designations, department } = action.payload;
      return { ...state, type, isChairman, dept_id, sub_office_id, name, username, email, designations, department };
    },
    logout: (state) => initialState,
  },
});

export const { login, setInfo, logout } = userSlice.actions;
export default userSlice.reducer;