import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { API } from '../../../constants/constant';
import { displayToastWithTimeout } from '../../../store/slices/uiSlice';
import { delay } from '../../../pages/Login';
import "./sty.css"

const BulkUploadMember = () => {
    const [file, setFile] = useState(null);
    const [results, setResults] = useState([]);
    const user = useSelector(s=>s.user)
    const dispatch = useDispatch()
    const tableHead = 'name, email, username, password, phone, additional_email, achievement, room_no, designation_id, department, sub_office_id, present'


    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            alert("Please upload a CSV file");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${API}/api/upload/bulk`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${user.token}`,
                }
            });

            setResults(response.data);
        } catch (error) {
            console.error('Error uploading CSV:', error);
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error getting faculty data.';
            dispatch(displayToastWithTimeout({ type: 'success', message: errorMessage, stayFor: 4000 }));
        }
    };

    return (
        <div>
            <h1 className='pageHead'>Bulk Upload Members</h1>
            <form className='fancyForm' onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="file">Upload CSV File:</label>
                    <input type="file" id="file" accept=".csv" onChange={handleFileChange} />
                </div>
                <input className='submit' type="submit" value={'Submit'}/>
            </form>

            <div className="flex column g10">
                <h2 className="head2">
                    Example Format
                </h2>
                <p>{tableHead}</p>
            </div>

            {results.length > 0 && (
                <div>
                    <h2>Upload Results</h2>
                    <ul className='ulList'>
                        {results.map((result, index) => result.accepted && (
                            <li key={index} style={{ color: 'white', background: result.accepted ? '#2b8461' : '#854e4e' }}>
                                Name: {result.name} <br /> Email: {result.email} || Username: {result.username} <br />
                                Status: {result.accepted ? 'Accepted' : 'Rejected'} || {result.message}
                            </li>
                        ))}
                    </ul>
                    <ul className='ulList'>
                        {results.map((result, index) => !result.accepted && (
                            <li key={index} style={{ color: 'white', background: result.accepted ? '#2b8461' : '#854e4e' }}>
                                Name: {result.name} <br /> Email: {result.email} || Username: {result.username} <br />
                                Status: {result.accepted ? 'Accepted' : 'Rejected'} || {result.message}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default BulkUploadMember;
