
import { useEffect, useState } from 'react';
import './style.css'
import '../style.css'
import { API } from '../../../constants/constant';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { displayToastWithTimeout, setIsLoading } from '../../../store/slices/uiSlice';
import { delay } from '../../../pages/Login';
import { useNavigate } from 'react-router-dom';
import MyModal from '../../modal/mymodal';
import SingleUser from './manageSingleUser';

const MemberComponent = ({data, onSuccess, query, oneTwo, setOneTwo})=> {

    const [navLink, setNavLink] = useState([])
    const [facultyMember, setFacultyMember] = useState([])
    const [officeMember, setOfficeMember] = useState([])
    const dispatch = useDispatch()
    const user = useSelector((s)=>s.user)
    const navigate = useNavigate()
    

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);


    const openModal = (content) => {
        setIsModalOpen(true);
        setModalContent(content);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };

    

    const handleDelete = async ( uid)=>{
      
        dispatch(setIsLoading(true))
        try {
            const response = await axios.delete(`${API}/api/delete/user/${uid}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            
            if (response.status === 200) {
                await delay(500)
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
                onSuccess()
                closeModal()
            } else {
                dispatch(displayToastWithTimeout({ type: 'error', message: response.data.message, stayFor: 4000 }));
            }

        } catch (error) {
            await delay(500)
            const errorMessage = error || 'Error deleting Office data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));


        } finally {


            dispatch(setIsLoading(false))
        }
    }

    const writeFdes= (data)=>{
        const des = data.map((dept) => {
            return dept.designation
        }).join(', ')
        return des
    }
    const setAlongQuery = async ()=>{
        
        let fm = []
        let om = []
        if(query === ''){
            fm = data.faculty
            om = data.office
        } else {
            fm = data.faculty.filter((member)=>{
                return member.name.toLowerCase().includes(query.toLowerCase()) || member.email.toLowerCase().includes(query.toLowerCase()) || member.phone.toLowerCase().includes(query.toLowerCase()) || member.departments.some((d) => d.shortname.toLowerCase().includes(query.toLowerCase()) || d.designation.toLowerCase().includes(query.toLowerCase()) || member.offices.some((d) => d.name.toLowerCase().includes(query.toLowerCase()) || d.designation.toLowerCase().includes(query.toLowerCase())))
            })
            om = data.office.filter((member) => {
                return member.name.toLowerCase().includes(query.toLowerCase()) || member.email.toLowerCase().includes(query.toLowerCase()) || member.phone.toLowerCase().includes(query.toLowerCase()) || member.offices.some((d) => d.name.toLowerCase().includes(query.toLowerCase()) || d.designation.toLowerCase().includes(query.toLowerCase()) || member.departments.some((d) => d.shortname.toLowerCase().includes(query.toLowerCase()) || d.designation.toLowerCase().includes(query.toLowerCase())))
            })
            
        }

    


        setFacultyMember(fm)
        setOfficeMember(om)
    }

    useEffect(()=>{
        setNavLink(Object.entries(data).map(([key, value], i) => {
            return key
        }))

        setAlongQuery()
    }, [data, query])
    
    return (
        <div style={{paddingTop: '10px'}}>
            <MyModal isOpen={isModalOpen} close={closeModal}>
                {modalContent}
            </MyModal>
            <div className="headNav">
                {
                    Array.isArray(navLink) && navLink.length > 0 ? (
                        <div className="flex g5 localNav">
                            {
                                navLink.map((d, i) => {
                                    return (
                                        <div style={{whiteSpace: 'nowrap'}} onClick={() => setOneTwo(i+1)} key={d} className={"localNavLink " + (oneTwo === i+1 ? 'active f1' : '')}>
                                            {d} members
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ) : (
                        <div></div>
                    )
                }
            </div>


            <div className="member-cards">
                {
                    oneTwo === 1 ? 
                        Array.isArray(facultyMember) && facultyMember.length > 0 ? 
                            facultyMember.map((d, i)=> {
                                const basicInfo = {
                                    name: d.name,
                                    phone: d.phone,
                                    additional_email: d.additional_email,
                                    achievement: d.achievement,
                                    profileImage: d.profileImage || null
                                }
                                return (
                                    <div key={i} className="member-card">
                                        <div>
                                            <div className="flex g5">
                                                <div className='f5'>
                                                    <div className="name">{d.name}</div>
                                                    <div className="designation">
                                                        {writeFdes(d.departments)}
                                                    </div>
                                                </div>
                                                <div className='f1 member-card-image'>
                                                    {
                                                        d.profile ? <img src={API + ('/uploads/') + d.profile} alt={d.name} /> : <img src={process.env.PUBLIC_URL + './assets/profile.svg'} alt={d.name} />
                                                    }
                                                </div>
                                            </div>
                                            <div className="email">{d.email}</div>
                                            <div className="username">{d.username} | {d.phone}</div>
                                        </div>
                                        <div className="department">
                                            {Array.isArray(d.departments) && d.departments[0].shortname}
                                        </div>
                                        <div className="extraOptions">
                                            <svg fill='black' 
                                                viewBox="0 0 6.3499999 6.35">
                                                <path fill='black' d='M 3.175 1.191 C 3.091 1.191 3.01 1.217 2.942 1.266 C 2.874 1.316 2.823 1.385 2.798 1.465 C 2.772 1.545 2.772 1.63 2.798 1.71 C 2.823 1.79 2.874 1.859 2.942 1.909 C 3.01 1.958 3.091 1.984 3.175 1.984 C 3.259 1.984 3.34 1.958 3.408 1.909 C 3.476 1.859 3.527 1.79 3.552 1.71 C 3.578 1.63 3.578 1.545 3.552 1.465 C 3.527 1.385 3.476 1.316 3.408 1.266 C 3.34 1.217 3.259 1.191 3.175 1.191 Z M 3.175 2.778 C 3.091 2.778 3.009 2.804 2.941 2.853 C 2.873 2.903 2.822 2.972 2.796 3.052 C 2.77 3.132 2.77 3.218 2.796 3.298 C 2.822 3.378 2.873 3.447 2.941 3.497 C 3.009 3.546 3.091 3.572 3.175 3.572 C 3.259 3.572 3.341 3.546 3.409 3.497 C 3.477 3.447 3.528 3.378 3.554 3.298 C 3.58 3.218 3.58 3.132 3.554 3.052 C 3.528 2.972 3.477 2.903 3.409 2.853 C 3.341 2.804 3.259 2.778 3.175 2.778 Z M 3.175 4.366 C 3.07 4.366 2.969 4.407 2.894 4.482 C 2.82 4.556 2.778 4.657 2.778 4.763 C 2.778 4.868 2.82 4.969 2.894 5.043 C 2.969 5.118 3.07 5.159 3.175 5.159 C 3.28 5.159 3.381 5.118 3.456 5.043 C 3.53 4.969 3.572 4.868 3.572 4.763 C 3.572 4.657 3.53 4.556 3.456 4.482 C 3.381 4.407 3.28 4.366 3.175 4.366 Z' />
                                            </svg>
                                            <div className="ops">
                                                <div className="op">
                                                    <div className="button edit" onClick={() => {
                                                        openModal(<UploadForm uid={d.uid} info={basicInfo} onSuccess={onSuccess} closeModal={closeModal}/>)
                                                    }}>Edit Info</div>
                                                    <div className="button add" onClick={()=>{
                                                        navigate(`/admin-panel/single-user/${d.uid}`)
                                                    }}>Add offices</div>
                                                    <div className="button delete" onClick={()=>{
                                                        openModal(<>
                                                            <SingleUser uiddd={d.uid} />
                                                            <h2>Delete This User</h2>
                                                            <div className="button delete" onClick={() => {
                                                                handleDelete(d.uid)
                                                            }}>Delete</div>
                                                        </>)
                                                    }}>Delete</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : (<h2>There is no Faculty Member</h2>)
                        : Array.isArray(officeMember) && officeMember.length > 0 ?
                            officeMember.map((d, i) => {
                                const basicInfo = {
                                    name: d.name,
                                    phone: d.phone,
                                    additional_email: d.additional_email,
                                    achievement: d.achievement,
                                    profileImage: d.profileImage || null
                                }
                                return (
                                    <div key={i} className="member-card">
                                        <div>
                                            <div className="flex g5">
                                                <div className='f3'>
                                                    <div className="name">{d.name}</div>
                                                    <div className="designation">
                                                        {writeFdes(d.offices)}
                                                    </div>
                                                </div>
                                                <div className='f1 member-card-image'>
                                                    {
                                                        d.profile ? <img src={API + ('/uploads/') + d.profile} alt={d.name} /> : <img src={process.env.PUBLIC_URL + '/assets/profile.svg'} alt={d.name} />
                                                    }
                                                </div>
                                            </div>
                                            <div className="email">{d.email}</div>
                                            <div className="username">{d.username} | {d.phone}</div>
                                          
                                        </div>
                                        <div className="extraOptions">
                                            <svg fill='black'
                                                viewBox="0 0 6.3499999 6.35">
                                                <path fill='black' d='M 3.175 1.191 C 3.091 1.191 3.01 1.217 2.942 1.266 C 2.874 1.316 2.823 1.385 2.798 1.465 C 2.772 1.545 2.772 1.63 2.798 1.71 C 2.823 1.79 2.874 1.859 2.942 1.909 C 3.01 1.958 3.091 1.984 3.175 1.984 C 3.259 1.984 3.34 1.958 3.408 1.909 C 3.476 1.859 3.527 1.79 3.552 1.71 C 3.578 1.63 3.578 1.545 3.552 1.465 C 3.527 1.385 3.476 1.316 3.408 1.266 C 3.34 1.217 3.259 1.191 3.175 1.191 Z M 3.175 2.778 C 3.091 2.778 3.009 2.804 2.941 2.853 C 2.873 2.903 2.822 2.972 2.796 3.052 C 2.77 3.132 2.77 3.218 2.796 3.298 C 2.822 3.378 2.873 3.447 2.941 3.497 C 3.009 3.546 3.091 3.572 3.175 3.572 C 3.259 3.572 3.341 3.546 3.409 3.497 C 3.477 3.447 3.528 3.378 3.554 3.298 C 3.58 3.218 3.58 3.132 3.554 3.052 C 3.528 2.972 3.477 2.903 3.409 2.853 C 3.341 2.804 3.259 2.778 3.175 2.778 Z M 3.175 4.366 C 3.07 4.366 2.969 4.407 2.894 4.482 C 2.82 4.556 2.778 4.657 2.778 4.763 C 2.778 4.868 2.82 4.969 2.894 5.043 C 2.969 5.118 3.07 5.159 3.175 5.159 C 3.28 5.159 3.381 5.118 3.456 5.043 C 3.53 4.969 3.572 4.868 3.572 4.763 C 3.572 4.657 3.53 4.556 3.456 4.482 C 3.381 4.407 3.28 4.366 3.175 4.366 Z' />
                                            </svg>
                                            <div className="ops">
                                                <div className="op">
                                                    <div className="button edit" onClick={() => {
                                                        openModal(<UploadForm uid={d.uid} info={basicInfo} onSuccess={onSuccess} closeModal={closeModal}/>)
                                                    }}>Edit Info</div>
                                                    <div className="button add" onClick={() => {
                                                        navigate(`/admin-panel/single-user/${d.uid}`)
                                                    }}>Add offices</div>
                                                    <div className="button delete" onClick={() => {
                                                        openModal(<>
                                                            <SingleUser uiddd={d.uid} />
                                                            <h2>Delete This User</h2>
                                                            <div className="button delete" onClick={()=>{
                                                                handleDelete(d.uid)
                                                            }}>Delete</div>
                                                        </>)
                                                    }}>Delete</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : (<h2>There is no Office Member</h2>)
                }
            </div>
        </div>
    )
}

export default MemberComponent


const UploadForm = ({ uid, info, onSuccess, closeModal }) => {

    const user = useSelector((s) => s.user)
    const dispatch = useDispatch()


    const [formData, setFormData] = useState({
        name: info.name,
        phone: info.phone,
        additional_email: info.additional_email || '',
        achievement: info.achievement,
        profileImage: info.profileImage
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            profileImage: e.target.files[0]
        });
    }; 

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append('name', formData.name);
        data.append('phone', formData.phone);
        data.append('additional_email', formData.additional_email);
        data.append('achievement', formData.achievement);
        if (formData.profileImage) {
            data.append('profileImage', formData.profileImage);
        }


        try {
            const response = await axios.put(`${API}/api/edit/user/${uid}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${user.token}`,
                }
            });
            if (response.status === 200) {
                await delay(500)
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
                onSuccess()
                closeModal()
            } else {
                dispatch(displayToastWithTimeout({ type: 'error', message: response.data.message, stayFor: 4000 }));
            }
            } catch (error) {
                await delay(500)
            const errorMessage = error?.response?.data.message || 'Error deleting Office data.';
                dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));


            } finally {


                dispatch(setIsLoading(false))
            }
    };

    return (
        <form className='fancyForm' onSubmit={handleSubmit}>
            <div>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div>
                <label htmlFor="phone">Phone:</label>
                <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
            </div>
            <div>
                <label htmlFor="additional_email">Additional Email:</label>
                <input type="email" id="additional_email" name="additional_email" value={formData.additional_email} onChange={handleChange} />
            </div>
            <div>
                <label htmlFor="achievement">Achievement:</label>
                <textarea id="achievement" name="achievement" value={formData.achievement} onChange={handleChange} required></textarea>
            </div>
            <div>
                <label htmlFor="profileImage">Profile Image:</label>
                <input type="file" id="profileImage" name="profileImage" onChange={handleFileChange} />
            </div>
            <input className='submit' type="submit" value={'Submit'}/>
        </form>
    );
};