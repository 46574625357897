
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { displayToastWithTimeout, setIsLoading } from '../../store/slices/uiSlice';
import { delay } from '../../pages/Login';
import './style.css'
import { useNavigate } from 'react-router-dom';
import { API } from '../../constants/constant';
import { fetchDesiredData } from '../../store/slices/dataSlice';


const Offices = () => {
  const [facultyData, setFacultyData] = useState([]);
  const user = useSelector((s)=> s.user)
  const dispatch = useDispatch()
  const nav = useNavigate()
  const apiUrl = `/api/get/offices`
  const data = useSelector((state)=>state.dataSliceState.data[apiUrl])
  const loading = useSelector((state)=>state.dataSliceState.loading)
  const error = useSelector((state)=>state.dataSliceState.error)




    useEffect(() => {
    if (!data) {
      dispatch(fetchDesiredData({ apiUrl, token: user.token }));
    } else {
      setFacultyData(data.offices)
    }
    dispatch(setIsLoading(false))

    const timeoutId = setTimeout(()=>dispatch(fetchDesiredData({ apiUrl, token: user.token })), 5000);
    return () => clearTimeout(timeoutId);

    
  }, [dispatch, apiUrl, data, user.token]);



  const gotoLink = async (office_id)=>{
    dispatch(setIsLoading(true))
    await delay(500);
    nav(`/sub-offices/${office_id}`)
  }



  return (
    <div>
      <h2 className="pageHead">Administrative Offices</h2>
      {loading && !data ? (
        'Loading...'
      ) : (
        <div className='facultyBoxContainer'>
          {facultyData.map((faculty) => (
            <div className='facultyBox' key={faculty.id} onClick={()=>gotoLink(faculty.office_id)}>
              <div className="flex x-center g10">
                <div className="image">
                  <img src={process.env.PUBLIC_URL + './assets/adm.svg'} alt="Office" />
                </div>
                <div className="f1">
                  <p className='capitalize'>{faculty.name}</p>
                  <p>Number of Sub Offices: {faculty.sub_offices_count}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Offices;
