import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { displayToastWithTimeout, setIsLoading } from "../../../store/slices/uiSlice";
import { delay } from "../../../pages/Login";
import { API } from "../../../constants/constant";
import './sty.css'
import MyModal from "../../modal/mymodal";


const SingleUser = ({uiddd})=>{
    const [pre, setPre] = useState(null)
    const user = useSelector((s)=>s.user)
    const dispatch = useDispatch()
    const [theUser, setTheUser] = useState(null)
    const nav = useNavigate()
    const [phone, setPhone] = useState([])
    const { uidd } = useParams()

    const uid = uidd || uiddd


    const fetchTheUser = async () => {

        dispatch(setIsLoading(true))

        try {
            const response = await axios.get(`${API}/api/get/user/${uid}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            setTheUser(response.data.userInfo)
            setPhone(response.data.userInfo.phone.split(','))
            const preee = response.data.userInfo.departments.length > 0 ? response.data.userInfo.departments[0].present : response.data.userInfo.offices[0].present
            setPre(preee)

        } catch (error) {
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error getting faculty data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

        } finally {
            dispatch(setIsLoading(false))
        }
    };

    const onScuccess = ()=>{
        fetchTheUser()
    }

    

   

    useEffect(() => {
        if(!uid){
            nav(-1)
        }
        if (theUser === null) {
            fetchTheUser()
        }
    }, [theUser])

    return (
        <div>
            {
                theUser && (
                    <div className="singleuser">
                        
                        <div className="singleuser_name">
                            {theUser.name}
                        </div>
                        <div className="flex g10 one wrap x-center space-between">
                            <div className="f3 dess">
                                <div className="singleuser_designation">
                                    {
                                        theUser.type == 1 ? (
                                            theUser.departments && <p>
                                                {theUser.departments[0].designation} {theUser.departments[0].present === 1 ? '' : '(On Study Leave)'} <br />
                                                <span className="dept">
                                                    Department of {theUser.departments[0].name}
                                                </span>
                                            </p>
                                        ) : (
                                            theUser.offices && <p>
                                                {theUser.offices[0].designation} <br />
                                                <span className="dept">
                                                    {theUser.offices[0].name}
                                                </span>
                                            </p>


                                        )
                                    }
                                </div>
                                <div className="singleuser_achievement">
                                    {theUser.achievement}
                                </div>
                            </div>
                            <div className="f1">
                                <div className="singleuser_email flex column g5">
                                    {theUser.email} <br />
                                    {theUser.additional_email && theUser.additional_email} <br />
                                    {
                                        phone.map((p)=>{
                                            return (
                                                <div key={p}>{p}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                                
                        <div className="twoTable">
                            <DesignationTable data={theUser.departments} type={1} uid={uid} onScuccess={onScuccess} present={pre} />
                            <DesignationTable data={theUser.offices} type={2} uid={uid} onScuccess={onScuccess} present={pre} />
                        </div>
                        


                    </div>
                )
            }
        </div>
    )

}

export default SingleUser






const DesignationTable = ({data, type, uid, onScuccess, present})=>{

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    

    const openModal = (content) => {
        setIsModalOpen(true);
        setModalContent(content);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };

    useEffect(() => {
        
    }, [data])

    return (
        <div className="dtcontainer">
            <MyModal isOpen={isModalOpen} close={closeModal}>
                {modalContent}
            </MyModal>
            <div className="flex column">
                <div className="head flex space-between">
                    <div>
                        Relative {type === 1 ? 'Dept' : 'Sub Office'}(s) Info 
                    </div>
                    <div className="addnew" onClick={() => { openModal(<AddNewDesignation uid={uid} type={type} onScuccess={onScuccess} present={present} closeModal={closeModal} />) }}>
                        Add New +
                    </div>
                </div>
                <div className="gapRatio h flex">
                    <div className="f1 desc">SL</div>
                    {
                        type === 1 ? (<div className="f3">Dept Name</div>) : (<div className="f3">S.O. Name</div>)
                    }
                    <div className="f3 desc">Designation</div>
                    <div className="f2 desc">Room No</div>
                    <div className="f2 desc actions">Actions</div>
                </div>
                {
                    Array.isArray(data) && data.map((d, i)=>{
                        return (
                            <div key={i} className="gapRatio flex">
                                <div className="f1 desc3">{i+1}</div>
                                {
                                    type === 1 ? <div className="f3">{ d.shortname }</div> : <div className="f3">{ d.name }</div>
                                }
                                <div className="f3 desc3">{ d.designation }</div>
                                <div className="f2 desc3">{ d.room_no }</div>
                                <div className="f2 desc3 flex g5 x-center justify-center">
                                    <div onClick={() => { openModal(<EditDesignation data={d} onScuccess={onScuccess} type={type} closeModal={closeModal} uid={uid} />) }} className="edit">
                                        <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M16.04 3.02001L8.16 10.9C7.86 11.2 7.56 11.79 7.5 12.22L7.07 15.23C6.91 16.32 7.68 17.08 8.77 16.93L11.78 16.5C12.2 16.44 12.79 16.14 13.1 15.84L20.98 7.96001C22.34 6.60001 22.98 5.02001 20.98 3.02001C18.98 1.02001 17.4 1.66001 16.04 3.02001Z" stroke="#ffffff" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M14.91 4.1499C15.58 6.5399 17.45 8.4099 19.85 9.0899" stroke="#ffffff" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )

}

//<DeleteTheData data={d} onSuccess={reloadOnSuccess} />

const AddNewDesignation = ({uid, type, onScuccess, present, closeModal})=> {

    const [depts, setDepts] = useState([])
    const [offices, setOffices] = useState([])
    const [designations, setDesignations] = useState([])
    const user = useSelector((s) => s.user)
    const dispatch = useDispatch()

    const fData = {
        uid: uid,
        dept_id: '',
        designation_id: '',
        sub_office_id: '',
        room_no: '',
        present: present,
    }

    const [formData, setFormData] = useState(fData);


    const fetchDepts = async () => {

        dispatch(setIsLoading(true))

        try {
            const response = await axios.get(`${API}/api/get/all-departments`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            setDepts(response.data.departments)
        } catch (error) {
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error getting faculty data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

        } finally {
            dispatch(setIsLoading(false))
        }
    };

    const fetchOffices = async () => {

        dispatch(setIsLoading(true))

        try {
            const response = await axios.get(`${API}/api/get/all-sub-offices`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            setOffices(response.data.subOffices)
        } catch (error) {
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error getting faculty data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

        } finally {
            dispatch(setIsLoading(false))
        }
    };

    const fetchDesignations = async () => {

        dispatch(setIsLoading(true))

        if (type === 1) {
            fetchDepts()
        } else if (type === 2) {
            fetchOffices()
        }

        try {
            const response = await axios.get(`${API}/api/get/all-designation`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            setDesignations(response.data)
        } catch (error) {
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error getting faculty data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

        } finally {
            dispatch(setIsLoading(false))
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (((!formData.dept_id && !formData.designation_id) || (!formData.designation_id && !formData.sub_office_id)) && !formData.room_no) {
            return dispatch(displayToastWithTimeout({ type: 'error', message: "All fields are required", stayFor: 4000 }));
        }

        dispatch(setIsLoading(true))
        const link = type === 1 ? `${API}/api/add/user-department/${uid}` : `${API}/api/add/user-suboffice/${uid}`
        try {
            const response = await axios.post(link, formData, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            if (response.status === 201) {
                await delay(500)
                setFormData(fData)
                onScuccess()
                closeModal()
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));

            } else {
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
            }

        } catch (error) {
            await delay(2000)
            const errorMessage = error.response?.data?.message || 'Error adding new Office data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

        } finally {
            dispatch(setIsLoading(false))
        }
    };
    


    useEffect(()=>{
        fetchDesignations()
    }, [])

    return(
        <div>
            <form className="f4 fancyForm" onSubmit={handleSubmit}>
                <h2>Assign User To This {type===1?'Department':'Sub Office'}</h2>

                {
                    type===1?
                        <div>
                            <label htmlFor="dept_id">Department</label>
                            <select required name="dept_id" value={formData.dept_id} onChange={handleChange}>
                                <option disabled value=''>Select Department</option>
                                {
                                    depts && depts.map((d, i) => {
                                        return (
                                            <option key={i} value={d.dept_id}>{d.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div> :
                        <div>
                            <label htmlFor="sub_office_id">Sub Office</label>
                            <select required name="sub_office_id" value={formData.sub_office_id} onChange={handleChange}>
                                <option disabled value=''>SelectSub Office</option>
                                {
                                    offices && offices.map((d, i) => {
                                        return (
                                            <option key={i} value={d.sub_office_id}>{d.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                }




                <div>
                    <label htmlFor="designation_id">Designation</label>
                    <select required name="designation_id" value={formData.designation_id} onChange={handleChange}>
                        <option disabled value=''>Select Designation</option>
                        {
                           designations && designations.map((d, i) => {
                                return (
                                    <option key={i} value={d.designation_id}>{d.name}</option>
                                )
                            })
                        }
                    </select>
                </div>

                <div>
                    <label htmlFor="room_no">Room No</label>
                    <input
                        type="text"
                        id="room_no"
                        placeholder='eg. 101'
                        name="room_no"
                        value={formData.room_no}
                        onChange={handleChange}
                        required
                    />
                </div>

                <input className='submit' type="submit" value={type===1? "Add to Department":'Add to Sub Office'} />
            </form>
        </div>
    )

}

const EditDesignation = ({ uid, type, data, closeModal, onScuccess }) => {

    const [depts, setDepts] = useState([])
    const [offices, setOffices] = useState([])
    const [designations, setDesignations] = useState([])
    const user = useSelector((s) => s.user)
    const dispatch = useDispatch()

    const fData = {
        dept_id: data.dept_id || null,
        designation_id: data.designation_id,
        sub_office_id: data.sub_office_id || null,
        room_no: data.room_no,
        present: data.present,
    }

    const [formData, setFormData] = useState(fData);


    const fetchDepts = async () => {

        dispatch(setIsLoading(true))

        try {
            const response = await axios.get(`${API}/api/get/all-departments`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            setDepts(response.data.departments)
        } catch (error) {
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error getting faculty data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

        } finally {
            dispatch(setIsLoading(false))
        }
    };

    const fetchOffices = async () => {

        dispatch(setIsLoading(true))

        try {
            const response = await axios.get(`${API}/api/get/all-sub-offices`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            setOffices(response.data.subOffices)
        } catch (error) {
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error getting faculty data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

        } finally {
            dispatch(setIsLoading(false))
        }
    };

    const fetchDesignations = async () => {

        dispatch(setIsLoading(true))

        if (type === 1) {
            fetchDepts()
        } else if (type === 2) {
            fetchOffices()
        }

        try {
            const response = await axios.get(`${API}/api/get/all-designation`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            setDesignations(response.data)
        } catch (error) {
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error getting faculty data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

        } finally {
            dispatch(setIsLoading(false))
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (((!formData.dept_id && !formData.designation_id) || (!formData.designation_id && !formData.sub_office_id)) && !formData.room_no) {
            return dispatch(displayToastWithTimeout({ type: 'error', message: "All fields are required", stayFor: 4000 }));
        }

        dispatch(setIsLoading(true))
        const link = type === 1 ? `${API}/api/update/user-department/${uid}` : `${API}/api/update/user-suboffice/${uid}`
        try {
            const response = await axios.put(link, formData, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            if (response.status === 200) {
                await delay(500)
                setFormData(fData)
                onScuccess()
                closeModal()
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));

            } else {
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
            }

        } catch (error) {
            await delay(1000)
            const errorMessage = error.response?.data?.message || 'Error adding new Office data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

        } finally {
            dispatch(setIsLoading(false))
        }
    };

    
    const handleDelete = async () => {
        dispatch(setIsLoading(true))
        const link = type === 1 ? `${API}/api/delete/user-department/${uid}/${data.dept_id}/${data.designation_id}` : `${API}/api/delete/user-suboffice/${uid}/${data.sub_office_id}/${data.designation_id}`
        try {
            const response = await axios.delete(link, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            if (response.status === 200) {
                await delay(500)
                onScuccess()
                closeModal()
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));

            } else {
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
            }

        } catch (error) {
            await delay(1000)
            const errorMessage = error.response?.data?.message || 'Error adding new Office data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

        } finally {
            dispatch(setIsLoading(false))
        }
    };



    useEffect(() => {
        fetchDesignations()
    }, [])

    return (
        <div>
            <form className="f4 fancyForm" onSubmit={handleSubmit}>
                <h2>Edit User To {type === 1 ? 'Department' : 'Sub Office'}</h2>

                {
                    type === 1 ?
                        <div>
                            <label htmlFor="dept_id">Department</label>
                            <select disabled required name="dept_id" value={formData.dept_id} onChange={handleChange}>
                                <option disabled value=''>Select Department</option>
                                {
                                    depts && depts.map((d, i) => {
                                        return (
                                            <option key={i} value={d.dept_id}>{d.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div> :
                        <div>
                            <label htmlFor="sub_office_id">Sub Office</label>
                            <select disabled required name="sub_office_id" value={formData.sub_office_id} onChange={handleChange}>
                                <option disabled value=''>SelectSub Office</option>
                                {
                                    offices && offices.map((d, i) => {
                                        return (
                                            <option key={i} value={d.sub_office_id}>{d.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                }
                <div>
                    <label htmlFor="designation_id">Designation</label>
                    <select disabled required name="designation_id" value={formData.designation_id} onChange={handleChange}>
                        <option disabled value=''>Select Designation</option>
                        {
                            designations && designations.map((d, i) => {
                                return (
                                    <option key={i} value={d.designation_id}>{d.name}</option>
                                )
                            })
                        }
                    </select>
                </div>

                <div>
                    <label htmlFor="room_no">Room No</label>
                    <input
                        type="text"
                        id="room_no"
                        placeholder='eg. 101'
                        name="room_no"
                        value={formData.room_no}
                        onChange={handleChange}
                        required
                    />
                </div>

                <input className='submit' type="submit" value={type === 1 ? "Add to Department" : 'Add to Sub Office'} />
                <input onClick={()=>handleDelete()} className='submit' type="button" value='Delete' />
            </form>
        </div>
    )

}