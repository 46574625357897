
import './style.css'

const UserComponent = ({ data, onSuccess }) => {

    console.log(data);
    return (
        <div>
            <h1>User Comp</h1>
        </div>
    )
}

export default UserComponent