import React, { useEffect, useState } from "react";
import "./auth.css";
import { useDispatch, useSelector } from "react-redux";
import { displayToastWithTimeout, setIsLoading } from "../../store/slices/uiSlice";
import { delay } from "../Login";
import axios from "axios";
import { API } from "../../constants/constant";
import { RESET_AUTH, SET_EMAIL } from "../../store/slices/authSlice";
import { useNavigate } from "react-router-dom";


const SetNewPassword = () => {
    const nav = useNavigate()
    const auth = useSelector((s) => s.auth)
    const dispatch = useDispatch()
    const [pass, setPass] = useState(null)
    const [confirmPass, setConfirmPass] = useState(null)
    const [passwordValidations, setPasswordValidations] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        numeric: false,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "password") {
            setPass(value);
            validatePassword(value);
        } else if (name === "confirmpassword") {
            setConfirmPass(value);
        }
    };

    const validatePassword = (password) => {
        const length = password.length >= 6;
        const uppercase = /[A-Z]/.test(password);
        const lowercase = /[a-z]/.test(password);
        const numeric = /[0-9]/.test(password);

        setPasswordValidations({
            length,
            uppercase,
            lowercase,
            numeric,
        });
    };

    const handlePasswordSubmit = async (e) => {

        e.preventDefault();

        if (!passwordValidations.length || !passwordValidations.uppercase || !passwordValidations.lowercase || !passwordValidations.numeric) {
            return dispatch(displayToastWithTimeout({ type: 'error', message: "Password does not meet all requirements", stayFor: 4000 }));
        }

        if(!(pass === confirmPass)){
            return dispatch(displayToastWithTimeout({ type: 'error', message: "Password not matched", stayFor: 4000 }));
        }
        dispatch(setIsLoading(true))

        try {
            const formData = {
                email: auth.email,
                code: auth.code,
                password: pass
            }
            const response = await axios.post(`${API}/api/users/set-new-password`, formData);
            if (response.status === 200) {
                dispatch(RESET_AUTH())
                await delay(500)
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
                nav('/login')
            }

        } catch (error) {
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error setting status.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));
        } finally {
            dispatch(setIsLoading(false))
        }
    }

    useEffect(() => {
        if (!auth.email || !auth.code) {
            nav('/forgot-password', { replace: true })
        }
    }, [])

    return (
        <div className="forgotPass">

            <div className="content">
                <h1 className="h1">Set New Password</h1>
                <form className="flex g20 column" onSubmit={handlePasswordSubmit}>
                    <div>
                        <input type="password" name="password" id="password" placeholder="Password" required onChange={handleChange} />
                    </div>
                    <div>
                        <input type="password" name="confirmpassword" id="confirmpassword" placeholder="Confirm Password" required onChange={handleChange} />
                    </div>
                    <div className="exceptions">
                        <p className={`exception ${passwordValidations.length ? 'hidden' : ''}`}>
                            Password should be at least 6 characters long
                        </p>
                        <p className={`exception ${passwordValidations.uppercase ? 'hidden' : ''}`}>
                            At least 1 uppercase letter
                        </p>
                        <p className={`exception ${passwordValidations.lowercase ? 'hidden' : ''}`}>
                            At least 1 lowercase letter
                        </p>
                        <p className={`exception ${passwordValidations.numeric ? 'hidden' : ''}`}>
                            At least 1 number or digit
                        </p>
                    </div>
                    <input type="submit" value="Save Password" />
                </form>
            </div>
            <img className="svg" src={process.env.PUBLIC_URL + '/assets/forget-pass.svg'} alt="" />
        </div>
    )
}

export default SetNewPassword