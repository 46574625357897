// src/Faculties.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from '../constants/constant';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading, displayToastWithTimeout } from '../store/slices/uiSlice';
import { delay } from '../pages/Login';
import './style.css'
import { useNavigate } from 'react-router-dom';
import { fetchDesiredData } from '../store/slices/dataSlice';


const Faculties = () => {
  const [facultyData, setFacultyData] = useState([]);
  const user = useSelector((s)=> s.user)
  const apiUrl = '/api/get/faculties'
  const data = useSelector((state)=>state.dataSliceState.data[apiUrl])
  const loading = useSelector((state)=>state.dataSliceState.loading)
  const dispatch = useDispatch()
  const nav = useNavigate()

  useEffect(() => {
    dispatch(setIsLoading(false))
    if (!data) {
      dispatch(fetchDesiredData({ apiUrl, token: user.token }));
    } else {
      setFacultyData(data)
    }
    dispatch(setIsLoading(false))
    const timeoutId = setTimeout(()=>dispatch(fetchDesiredData({ apiUrl, token: user.token })), 5000);
    return () => clearTimeout(timeoutId);
  }, [data, user.token]);

  const gotoLink = async (faculty_id)=>{
    dispatch(setIsLoading(true))
    await delay(500);
    nav(`/department/${faculty_id}`)
  }



  return (
    <div>
      <h2 className="pageHead">Faculties</h2>
      {loading && !data ? (
        'Loading...'
      ) : (
        <div className='facultyBoxContainer'>
          {facultyData && facultyData.map((faculty) => {
            const newName = faculty.name.split('Faculty of ').join('')
            
            return (
            <div style={{
              background: `linear-gradient(90deg, rgb(163, 76, 255, 0.37), rgba(0, 0, 0, 0.6)), url(${process.env.PUBLIC_URL}./assets/new/${faculty.faculty_id}.jpeg)`
            }} className='facultyBox' key={faculty.id} onClick={()=>gotoLink(faculty.faculty_id)}>
              <div className="flot">Faculty Of</div>
              <div className="flex x-center g10">
                
                <div className="f1 capitalize">
                  <p>{newName}</p>
                  <p>Number of Department: {faculty.department_count}</p>
                </div>
              </div>
            </div>
          )})}
        </div>
      )}
    </div>
  );
};

export default Faculties;
