import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../store/slices/uiSlice";
import "./style.css";
import { useLocation } from "react-router-dom";

const AboutPage =()=>{

    const dis = useDispatch()
    const location = useLocation();
    const teamMembers = [
    {
        name: 'Prof. Dr. Syed Md. Galib',
        rank: 'Supervisor',
        email: 'galib.cse@just.edu.bd',
        social: {
            linkedin: 'https://bd.linkedin.com/in/syedgalib',
        },
    },
    {
        name: 'Saber Mahmud Sourav',
        rank: 'Developer',
        email: 'sabermahmud.sourav.7@gmail.com',
        social: {
        linkedin: 'https://www.linkedin.com/in/saber-mahmud-sourav-930211193/',
        github: 'https://github.com/sabermh46',
        },
    },
    ];


    useEffect(()=>{
        dis(setIsLoading(false))
    }, [])
    

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

    return (
        <div className="about-us">
            <header className="about-us-header">
                <div className="logo flex column x-center">
                    <h1>JUST</h1>
                    <h2>Digital Diary</h2>
                </div>
            </header>

            <section className="about-us-section">
                <h2>About The App</h2>
                <p>
                    JUST Digital Diary is a comprehensive digital platform designed for Jashore University of Science and Technology (JUST). It serves as a central hub for students, faculty, and staff to manage interconnectivity. With features like real-time updates, and easy access to resources, JUST Digital Diary enhances communication and collaboration within the university community. By integrating modern technology with educational processes, JUST Digital Diary aims to streamline daily operations and enhance the overall experience at JUST.
                </p>
            </section>

            <section className="mission-vision-section">
                <h2>Mission & Vision</h2>
                <p>
                    Our mission is to create a learning environment that nurtures innovation, critical thinking, and personal growth. 
                    We strive to be a leader in higher education by continuously improving our academic programs and fostering a culture of inclusivity and respect.
                </p>
                <p>
                    Our vision is to be recognized as a global leader in education, research, and community engagement. 
                    We aim to empower our students to make a positive impact on the world and to become responsible global citizens.
                </p>
            </section>

            <section className="our-team" id="our-team">
                <h2>Our Team</h2>
                <div className="team-container">
                    {teamMembers.map((member, index) => (
                    <div className="team-member" key={index}>
                        <h3>{member.name}</h3>
                        <p>{member.rank}</p>
                        <a className="desc" href={`mailto:${member.email}`}>{member.email}</a>
                        <div className="social-links">
                        {member.social.linkedin && (
                            <a href={member.social.linkedin} target="_blank" rel="noopener noreferrer">
                            LinkedIn
                            </a>
                        )}
                        {member.social.twitter && (
                            <a href={member.social.twitter} target="_blank" rel="noopener noreferrer">
                            Twitter
                            </a>
                        )}
                        {member.social.github && (
                            <a href={member.social.github} target="_blank" rel="noopener noreferrer">
                            GitHub
                            </a>
                        )}
                        </div>
                    </div>
                    ))}
                </div>
                </section>
        </div>
    );
}
export default AboutPage