import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { displayToastWithTimeout, setIsLoading } from '../../store/slices/uiSlice';
import axios from 'axios';
import { API } from '../../constants/constant';
import { delay } from '../Login';
import './style.css'
import { fetchDesiredData } from '../../store/slices/dataSlice';

const SubOffices = () => {
  // Use useParams to get the dynamic parameter (faculty_id) from the URL
  const { office_id } = useParams();
  const nav = useNavigate()
  const [suboffice, setSuboffice] = useState(null)
  const dispatch = useDispatch()
  const user = useSelector((s)=>s.user)
  const apiUrl = `/api/get/sub-offices/${office_id}`
  const data = useSelector((state)=>state.dataSliceState.data[apiUrl])
  const loading = useSelector((state)=>state.dataSliceState.loading)

  useEffect(() => {
      if (!data) {
        dispatch(fetchDesiredData({ apiUrl, token: user.token }));
      } else {
        setSuboffice(data.sub_offices)
      }
      dispatch(setIsLoading(false))

      const timeoutId = setTimeout(()=>dispatch(fetchDesiredData({ apiUrl, token: user.token })), 5000);
    return () => clearTimeout(timeoutId);
    }, [dispatch, apiUrl, data, user.token]);


  const gotoLink = async (sub_office_id)=>{
    dispatch(setIsLoading(true))
    await delay(500);
    nav(`/office-members/${sub_office_id}`)
  }


  return (
    <div>
      <h2 className='pageHead'>All Sub Offices</h2>
      <p className='subTitle'>Under</p>
      <h3 className='extraTitle'>{data && data.office_name}</h3>
      <div className="departments">
        {
            suboffice && suboffice.map((m)=>{
                return (
                    <div key={m.sub_office_id} className="dept" onClick={()=>gotoLink(`${m.sub_office_id}`)}>
                       <div className="flex x-center g10">
                            
                            <div className="flex column">
                                <p className="name capitalize">
                                    {m.name}
                                </p>
                                <div className="count">
                          Total: {m.office_members_count} Members
                                </div>
                            </div>
                        </div>
                        <p className="float right">
                            {m.dept_id}
                        </p>
                    </div>
                )
            })
        }
      </div>
    </div>
  );
};

export default SubOffices;