// src/Login.jsx
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { API } from '../constants/constant';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../store/slices/userSlice';
import { displayToastWithTimeout, setIsLoading } from '../store/slices/uiSlice';
import './welcome/style.css'
import { SET_EMAIL } from '../store/slices/authSlice';

export const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const Login = () => {
  const isLoading = useSelector((s)=>s.ui.isLoading)
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    identifier: '',
    password: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const nav = useNavigate()

  const handleLogin = async (e) => {
    e.preventDefault();


      try {
        dispatch(setIsLoading(true))
        const response = await axios.post(`${API}/api/users/login`, formData);

        const token = response.data.jwt
        const role = response.data.role

        await delay(500)
        if (response.data.message === 'Please confirm your account first') {
          dispatch(SET_EMAIL(formData.identifier))
          nav('/confirm-account')
          dispatch(displayToastWithTimeout({ type: 'success', message: "Please confirm your account first", stayFor: 4000 }));
        } else if(response.status === 200){
          dispatch(login({ token, role }));
          nav('/');
          dispatch(displayToastWithTimeout({ type: 'success', message: "Login Success", stayFor: 4000 }));
        } else {
          dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
        }
        
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'Login failed. Please try again.';
        dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));
      } finally {
        dispatch(setIsLoading(false))
      }
  };

  



  useEffect(()=>{
    dispatch(setIsLoading(false))
  }, [])

  return (
    <div>
      <div className="flex x-center wrap">
        <div className="f1">
          <h2 className='welcome-text'>Welcome to</h2>
          <div className="logo flex column x-center">
            <h1>JUST</h1>
            <h2>Digital Diary</h2>
          </div>
        </div>
        <div className="f1 logoImage">
          <img src={process.env.PUBLIC_URL + '/assets/logo.png'} alt="Logo" />
        </div>
      </div>
      <h2 className='pageHead'>Login</h2>
      <form className='form' onSubmit={handleLogin}>
      <div>
          <label htmlFor="identifier">Username/Email:</label>
          <input
            type="text"
            id="identifier"
            placeholder='eg. abul12'
            name="identifier"
            value={formData.identifier}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            placeholder='Your Password'
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
      <input className='submit' type="submit" value="login" />
      <p className="flex wrap x-center space-between g20">
        <span>
          Don't Have an account?
        </span>
        <button className='btn loginhere' onClick={async()=>{
            dispatch(setIsLoading(true))
            await delay(1000)
            nav('/register')
          }}>
            Sign Up Here
          </button>
      </p>
      <div>
          <a className='fpass' href="/forgot-password">Forgot Password?</a>
      </div>
    </form>
    </div>
  );
};

export default Login;
