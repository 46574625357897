import axios from "axios";
import { react, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../constants/constant";
import { displayToastWithTimeout, setIsLoading } from "../../store/slices/uiSlice";
import { delay } from "../../pages/Login";


const ManageGallery = ()=> {
    const user = useSelector((s) => s.user)
    const dispatch = useDispatch()

    const [images, setImages] = useState([]);
    const [captions, setCaptions] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [preImage, setPreImage] = useState(1)
    

    const handleImageChange = (e) => {
        const fileList = Array.from(e.target.files);
        setImages(fileList);
    };

    const handleCaptionChange = (e, index) => {
        const newCaptions = [...captions];
        newCaptions[index] = e.target.value;
        setCaptions(newCaptions);
    };

    const handlePriorityChange = (e, index) => {
        const newPriorities = [...priorities];
        newPriorities[index] = e.target.value;
        setPriorities(newPriorities);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(setIsLoading(true))
        try {
            const formData = new FormData();
            images.forEach((image, index) => {
                formData.append('images', image);
                formData.append('captions', captions[index]);
                formData.append('priorities', priorities[index]);
            });


            const response = await axios.post(`${API}/api/upload/images`, formData, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            // console.log(response.data);

            if (response.status === 201) {
                setImages([]);
                setCaptions([]);
                setPriorities([]);
                document.getElementById('image-upload').value = '';
                delay(500)
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
                
            }

        } catch (error) {
            // console.log(error);
            dispatch(displayToastWithTimeout({ type: 'error', message: error.response?.data?.message, stayFor: 4000 }));
        } finally {
            setPreImage((preImage) => preImage + 1)
            dispatch(setIsLoading(false))
        }
    };


    

    useEffect(()=>{
    }, [])


    return (
        <div className="AddADept">
            <div className="flex wrap g20">
                <form style={{position: "relative"}} onSubmit={handleSubmit} className="fancyForm f4">
                    <div>
                        <label htmlFor="image-upload">Select Images:</label>
                        <input
                            type="file"
                            id="image-upload"
                            accept="image/*"
                            multiple
                            onChange={handleImageChange}
                        />
                    </div>
                    {images.map((image, index) => (
                        <div key={index} className="flex wrap g10 x-center" style={{ flexDirection: 'row' }}>
                            <div style={{ width: '160px', height: '100px', borderRadius: '6px', overflow: "hidden" }}>
                                {image && (
                                    <img
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        src={URL.createObjectURL(image)}
                                        alt={`Image ${index}`}
                                    />
                                )}
                            </div>
                            <div className="f6">
                                <label htmlFor={`caption-${index}`}>Caption:</label>
                                <input
                                    type="text"
                                    id={`caption-${index}`}
                                    value={captions[index] || ''}
                                    onChange={(e) => handleCaptionChange(e, index)}
                                />
                            </div>
                            <div className="f2">
                                <label htmlFor={`priority-${index}`}>Priority:</label>
                                <input
                                    type="number"
                                    id={`priority-${index}`}
                                    value={priorities[index] || ''}
                                    onChange={(e) => handlePriorityChange(e, index)}
                                />
                            </div>
                        </div>
                    ))}

                    <input className="submit" type="submit" value='Upload Images' />
                </form>
                <RecentGalleryImages value={preImage} />
            </div>
        </div>
    )

}

export default ManageGallery



const RecentGalleryImages = ({value})=> {

    const user = useSelector((s) => s.user)
    const dispatch = useDispatch()
    const [grid, setGrid] = useState(2)

    

    const [selectedImage, setSelectedImage] = useState([]);
    const [otherImage, setOtherImage] = useState([]);
    const [isChanged, setisChanged] = useState(false)
    const [imageIds, setImageIds] = useState([])
    const [images, setImages] = useState([])
    const [wantBatch, setWantBatch] = useState(false)
    const [batchItemId, setBatchItemId] = useState([])


    const fetchGalleryImage = async () => {
        dispatch(setIsLoading(true))
        try {

            const response2 = await axios.get(`${API}/api/gallery/all-images`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            delay(500)
            dispatch(setIsLoading(false))
            setSelectedImage(response2.data.images.filter((d) => d.isSelected && d.isSelected === 1 ? d : false)
                .sort((a, b) => a.priority - b.priority));
            setOtherImage(response2.data.images.filter((d) => d.isSelected && d.isSelected === 1 ? false : d))

        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Error getting gallery data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

        }
    }

    const handleImageSelect = (image) => {
        if (selectedImage.find((selectedImg) => selectedImg.id === image.id)) {
            const updatedSelectedImages = selectedImage.filter((selectedImg) => selectedImg.id !== image.id);
            setSelectedImage(updatedSelectedImages);
            handleImageReorder(updatedSelectedImages);
            setOtherImage([...otherImage, image]);
        } else if (otherImage.find((otherImg) => otherImg.id === image.id)) {
            const updatedOtherImages = otherImage.filter((otherImg) => otherImg.id !== image.id);
            setOtherImage(updatedOtherImages);
            setSelectedImage([...selectedImage, image]);
            handleImageReorder([...selectedImage, image])
        }
    };

    const handleImageReorder = (updatedSelectedImages) => {
        setisChanged(true)
        setSelectedImage(updatedSelectedImages);
        prepareToUpload(updatedSelectedImages)
    };

    const prepareToUpload = (ddd)=> {
        setImages(ddd.map((e, i) => { return { 'id': e.id, 'priority': i + 1 } }))
        setImageIds(ddd.map((e, i) => e.id))
        
    }

    

    const handleButtonClick = (imageId) => {
        const index = batchItemId.indexOf(imageId);

        if (index === -1) {
            // If not found, add it to batchItemId
            setBatchItemId([...batchItemId, imageId]);
        } else {
            // If found, remove it from batchItemId
            const updatedBatchItemId = [...batchItemId];
            updatedBatchItemId.splice(index, 1);
            setBatchItemId(updatedBatchItemId);
        }
    };

    const handleDelete = async (e)=> {
        e.preventDefault();
        dispatch(setIsLoading(true))
        try {
            const response = await axios.put(`${API}/api/gallery/delete/images`, {'imageIds': batchItemId}, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            if (response.status === 200) {
                fetchGalleryImage()
                delay(500)
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));

            }

        } catch (error) {
            dispatch(displayToastWithTimeout({ type: 'success', message: error.response?.data?.message, stayFor: 4000 }));
        } finally {
            dispatch(setIsLoading(false))
        }
    }


    const handleSave = async (e)=>{
        
        e.preventDefault();
        dispatch(setIsLoading(true))
        try {
            const response = await axios.post(`${API}/api/gallery/update/selected`, {imageIds}, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            const response2 = await axios.post(`${API}/api/gallery/update/priority`, {images}, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            if (response.status === 200 && response.status === 200) {
                fetchGalleryImage()
                setisChanged(false)
                delay(500)
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
            }

        } catch (error) {
            dispatch(displayToastWithTimeout({ type: 'success', message: error.response?.data?.message, stayFor: 4000 }));
        } finally {
            dispatch(setIsLoading(false))
        }

    }

    useEffect(() => {
            fetchGalleryImage();
    }, [value])


    return (
        <div className="pd-y-5">
            <h2 className="head2">Recent Gallery Images</h2>
            
            <div className="the-optons flex wrap g10 space-between">
                <div className="f1 flex x-center justify-center avop">Available <br /> Options</div>
                <div className="f5 flex x-center space-between">
                    <div className="buttons flex g10 x-center space-between">
                        {
                            isChanged && isChanged && !wantBatch ? (
                                <div className="save-changes" onClick={handleSave}>
                                    Save Changes
                                </div>
                            ) : wantBatch && wantBatch ? (
                                <div className="flex wrap g5">
                                    <div onClick={() => setWantBatch((e) => !e)} className="save-changes">Cancle</div>
                                    <div onClick={handleDelete} className="save-changes">Delete</div>
                                </div>
                            ) : (
                                <div>
                                    <div onClick={() => setWantBatch((e) => !e)} className="save-changes">
                                        BATCH DELETE
                                    </div>
                                </div>
                            )
                        }

                    </div>
                    <div className="incDec">
                        <div className="name">Grid</div>
                        <div className="triggers">
                            <button onClick={()=>{setGrid((v)=> v-1)}} disabled={grid < 2 ? true : false}>-</button>
                            <div className="value">{grid}</div>
                            <button onClick={() => { setGrid((v) => v + 1) }} disabled={grid > 3 ? true : false}>+</button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                

                
                <SelectedImages images={selectedImage} grid={grid} handleImageSelect={handleImageSelect} handleImageReorder={handleImageReorder} isHidden={wantBatch && wantBatch ? false : true} />
                <div className="brake">Others Uploaded</div>
                <NotSelectedImages images={otherImage} grid={grid} handleImageSelect={handleImageSelect} batch={wantBatch} serveBatchId={handleButtonClick} />

                
            </div>
        </div>
    )


}

const SelectedImages = ({ images, grid = 2, handleImageSelect, handleImageReorder, isHidden = false })=>{

    const [draggedItemIndex, setDraggedItemIndex] = useState(null);
    const [hoveredItemIndex, setHoveredItemIndex] = useState(null);
    const [touchMoveIndex, setTouchMoveIndex] = useState(null);

    const handleDragStart = (index) => {
        setDraggedItemIndex(index);
    };

    const handleDragOver = (index) => {
        if (index !== draggedItemIndex) {
            setHoveredItemIndex(index);
        }
    };

    const handleDragEnd = () => {
        setDraggedItemIndex(null);
        setHoveredItemIndex(null);
        setTouchMoveIndex(null);
    };

    const handleDrop = (index) => {
        if (draggedItemIndex !== index) {
            const updatedImages = [...images];
            const draggedItem = updatedImages[draggedItemIndex];
            updatedImages[draggedItemIndex] = updatedImages[index];
            updatedImages[index] = draggedItem;

            handleImageReorder(updatedImages);
        }
        
        
        handleDragEnd()
        
    };

    // const handleDragEnter = (index) => {
    //     setHoveredItemIndex(index);
    // };

    const handleDragLeave = () => {
        setHoveredItemIndex(null);
    };

    const handleTouchStart = (index) => {

        setDraggedItemIndex(index);
    };

    const handleTouchMove = (e) => {
        const touch = e.touches[0];
        let element = document.elementFromPoint(touch.clientX, touch.clientY);


        if (element) {
            const index = element.getAttribute('index')
            if (!isNaN(index) && index !== touchMoveIndex) {
                setTouchMoveIndex(index);
                handleDragOver(index);
            }
        }
    };

    const handleTouchEnd = () => {
        if (touchMoveIndex !== null) {
            handleDrop(touchMoveIndex);
        } else {
            handleDragEnd();
        }
    };


    useEffect(()=>{
    }, [])
    return(
        <div className={isHidden ? 'cont visible' : 'cont hidden'}>
            <div className="child">
                <div className={"brake"}>Saved For Gallery</div>
                <div className={"g-images " + ("c" + grid.toString()) + (draggedItemIndex ? " dragging" : ' not')}>
                    {
                        Array.isArray(images) && images.length > 0 ?
                            images.map((image, index) => {
                                return (
                                    <div key={image.id} index={index} className={"g-image " + (image.isSelected && image.isSelected ? "selected " : " n ") + ((index === hoveredItemIndex) ? 'hovered' : (index === draggedItemIndex) ? 'dragged' : 'none')}

                                        onDragStart={() => { handleDragStart(index) }}
                                        onDragOver={(e) => {
                                            e.preventDefault();
                                            handleDragOver(index);
                                        }}
                                        onTouchStart={() => handleTouchStart(index)}
                                        onTouchMove={(e) => handleTouchMove(e)}
                                        onTouchEnd={handleTouchEnd}
                                        onDragEnd={handleDragEnd}
                                        onDrop={() => handleDrop(index)}
                                        onDragEnter={() => handleDragOver(index)}
                                        onDragLeave={handleDragLeave}
                                        onMouseEnter={() => { setHoveredItemIndex(index) }}
                                        onMouseLeave={() => { setHoveredItemIndex(null) }}
                                        draggable
                                        style={{ background: `url('${API}/uploads/${image.image}') center center / cover no-repeat white`}}
                                    >
                                        <p className={"index"}>{index + 1}</p>
                                        {/* <img src={`${API}/uploads/${image.image}`} alt={image.caption + `${image.priority}`} /> */}
                                        <div className="extra" onClick={() => { handleImageSelect(image) }}>
                                            To Other Section
                                        </div>
                                    </div>
                                )
                            })
                            :
                            "Loading..."
                    }
                </div>
            </div>
        </div>
    )
}

const NotSelectedImages = ({ images, grid = 2, handleImageSelect, batch = false, serveBatchId }) => {

    const [batchItemId, setBatchItemId] = useState([])


    const handleChange = (imageId) => {
        
        serveBatchId(imageId)
        const index = batchItemId.indexOf(imageId);

        if (index === -1) {
            // If not found, add it to batchItemId
            setBatchItemId([...batchItemId, imageId]);
        } else {
            // If found, remove it from batchItemId
            const updatedBatchItemId = [...batchItemId];
            updatedBatchItemId.splice(index, 1);
            setBatchItemId(updatedBatchItemId);
        }
        
    };

    

    useEffect(() => {
        if(!batch){
            setBatchItemId([])
        }
    }, [batch])
    return (
        <div className={"g-images " + ("c" + grid.toString())}>
            
            {
                Array.isArray(images) ? images.length > 0 ?
                    (images.map((image, index) => {
                        return (
                            <div onClick={() => { batch ? handleChange(image.id) : handleImageSelect(image)
                             }} key={image.id} className={"g-image " + (image.isSelected && image.isSelected ? "selected" : "n")}>
                                <p className={"index"}>{index + 1}</p>
                                <img src={`${API}/uploads/${image.image}`} alt={image.caption} />
                                <input style={{
                                    display: batch ? 'inline' : 'none'
                                }}
                                onClick={(e)=>e.stopPropagation()}
                                    type="checkbox"
                                    checked={batchItemId.includes(image.id) ? true : false}
                                    onChange={() => handleChange(image.id)}
                                    value={image.id}
                                />
                            </div>
                        )
                    })) : (
                        <h2>All images are saved for gallery</h2>
                    )
                    :
                    "Loading..."
            }
        </div>
    )
}