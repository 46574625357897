import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../constants/constant";
import axios from "axios";
import { displayToastWithTimeout, setIsLoading } from "../../store/slices/uiSlice";
import { delay } from "../../pages/Login";
import './style.css'
import MyModal from "../modal/mymodal";

const AddAnSubOffice = () => {
    const [officeData, setOfficeData] = useState([]);
    const [subOfficeData, setSubOfficeData] = useState([]);
    const user = useSelector((s) => s.user)
    const [load, setLoad] = useState(false)
    const dispatch = useDispatch()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const openModal = (content) => {
        setIsModalOpen(true);
        setModalContent(content);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };
    
    const fData = {
        name: "",
        sub_office_id: '',
        office_id: '',
    }

    const [formData, setFormData] = useState(fData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!formData.name || !formData.sub_office_id || !formData.office_id) {
            return dispatch(displayToastWithTimeout({ type: 'error', message: "All fields are required", stayFor: 4000 }));
        }
        
        dispatch(setIsLoading(true))
        try {
            const response = await axios.post(`${API}/api/add/sub-office`, formData, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            if (response.status === 201) {
                await delay(500)
                setFormData(fData)
                upSOffice()
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
                
            } else {
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
            }

        } catch (error) {
            await delay(2000)
            const errorMessage = error.response?.data?.message || 'Error adding new Office data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

        } finally {
            dispatch(setIsLoading(false))
        }
    };

    const upSOffice = async () => {
        dispatch(setIsLoading(true))
        try {
            const response = await axios.get(`${API}/api/get/all-sub-offices`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            await delay(500)
            
            setSubOfficeData(response.data.subOffices)
        } catch (error) {
            const errorMessage = error || 'Error fetching sub Office data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

        } finally {
            dispatch(setIsLoading(false))
        }
    }

        


    const reloadOnSuccess = () => {
        upSOffice();
        closeModal()
    };

    useEffect(() => {

        const fetchOfficeData = async () => {

            dispatch(setIsLoading(true))
            try {

                const response2 = await axios.get(`${API}/api/get/offices`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                await delay(500)
                dispatch(setIsLoading(false))
                setOfficeData(response2.data.offices);

            } catch (error) {
                const errorMessage = error || 'Error fetching sub Office data.';
                dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

            }
        };
        
        if (Array.isArray(subOfficeData) && subOfficeData.length < 1) {
            upSOffice()
        }
        fetchOfficeData();

    }, [subOfficeData]);



    return (
        <div className="AddADept">
            <MyModal isOpen={isModalOpen} close={closeModal}>
                {modalContent}
            </MyModal>
            
            <div className="flex wrap g20">
                <form className="f4 fancyForm" onSubmit={handleSubmit}>
                    <h2>Add A Sub Office</h2>
                    <div>
                        <label htmlFor="name">Sub Office Name</label>
                        <input
                            type="text"
                            id="name"
                            placeholder='eg. Admin Office'
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="sub_office_id">Sub Office Id</label>
                        <input
                            type="text"
                            id="sub_office_id"
                            placeholder='eg. 01'
                            name="sub_office_id"
                            value={formData.sub_office_id}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="office_id">Under</label>
                        <select required name="office_id" value={formData.office_id} onChange={handleChange}>
                            <option disabled value=''>Select Office</option>
                            {
                                officeData && officeData.map((d) => {
                                    return (
                                        <option key={d.id} value={d.office_id}>{d.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    <input className='submit' type="submit" value="Add A Sub Office" />
                </form>
                <div className="f3">
                    <h2 className="head2">Recent Sub Offices</h2>
                    {
                        subOfficeData && subOfficeData ?
                            <div className="officecards">
                                {
                                    subOfficeData.toReversed().map((d) => {
                                        return (
                                            <div key={d.id} className="officecard ctrl">
                                                <div className="flex column g20 space-between">
                                                    <h1>{d.name}</h1>
                                                    <p>Sub Office Id: <b>{d.sub_office_id}</b></p>
                                                </div>
                                                <div onClick={() => { openModal(<EditTheData data={d} onSuccess={reloadOnSuccess} />) }} className="edit">
                                                    <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M16.04 3.02001L8.16 10.9C7.86 11.2 7.56 11.79 7.5 12.22L7.07 15.23C6.91 16.32 7.68 17.08 8.77 16.93L11.78 16.5C12.2 16.44 12.79 16.14 13.1 15.84L20.98 7.96001C22.34 6.60001 22.98 5.02001 20.98 3.02001C18.98 1.02001 17.4 1.66001 16.04 3.02001Z" stroke="#ffffff" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M14.91 4.1499C15.58 6.5399 17.45 8.4099 19.85 9.0899" stroke="#ffffff" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                                                    <p>Edit</p>
                                                </div>
                                                <div onClick={() => { openModal(<DeleteTheData data={d} onSuccess={reloadOnSuccess} />) }} className="delete">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="16px" height="16px" viewBox="0 0 24 24"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"></path></g></svg>
                                                    <p>Delete</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : "Loading..."
                    }
                </div>
            </div>
        </div>
    );
}

export default AddAnSubOffice




const DeleteTheData = ({ data, onSuccess }) => {

    const user = useSelector((s) => s.user)
    const dispatch = useDispatch()

    const handleDelete = async (e) => {
        e.preventDefault();
        dispatch(setIsLoading(true))
        try {
            const response = await axios.delete(`${API}/api/delete/sub-office/${data.sub_office_id}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            if (response.status === 200) {
                await delay(500)
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
                onSuccess()
            } else {
                dispatch(displayToastWithTimeout({ type: 'error', message: response.data.message, stayFor: 4000 }));
            }

        } catch (error) {
            await delay(500)
            const errorMessage = error || 'Error deleting Office data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));


        } finally {


            dispatch(setIsLoading(false))
        }
    }

    useEffect(() => {
        dispatch(setIsLoading(false))
    }, [])

    return (
        <div className="deleteOffice">
            <h2>Delete this Sub Office :</h2>
            <p>Sub Office Name: <b>{data.name}</b></p>
            <p>Sub Office id: <b>{data.sub_office_id}</b></p>
            <input onClick={handleDelete} className='submit' type="submit" value="Confirm Delete" />
        </div>
    )
}

const EditTheData = ({ data, onSuccess }) => {

    const user = useSelector((s) => s.user)
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({ name: data.name });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();

        dispatch(setIsLoading(true))
        try {
            const response = await axios.put(`${API}/api/edit/sub-office/${data.sub_office_id}`, formData, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            if (response.status === 200) {
                await delay(500)
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
                onSuccess()
            } else {
                dispatch(displayToastWithTimeout({ type: 'error', message: response.data.message, stayFor: 4000 }));
            }

        } catch (error) {
            await delay(500)
            const errorMessage = error || 'Error deleting Office data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));
        } finally {
            dispatch(setIsLoading(false))
        }
    }

    useEffect(() => {

    }, [])

    return (
        <div className="deleteOffice">
            <h2 className="heading">Update Office with Office Id: {data.office_id}</h2>
            <form className="f4 fancyForm" onSubmit={handleUpdate}>
                <div>
                    <label htmlFor="name">Office Name</label>
                    <input
                        type="text"
                        id="name"
                        placeholder='eg. Admin Office'
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>

                <input className='submit' type="submit" value="Update" />
            </form>
        </div>
    )
}