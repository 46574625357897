import React, { useState } from 'react';
import "./modal.css"

const MyModal = ({ isOpen, close, children }) => {
    if (!isOpen) {
        return null;
    }

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div onClick={close} className="modal">
            
            <div onClick={handleModalClick} className="modal-body">
                <button className='close' onClick={close}>&times;</button>
                {children}
            </div>
        </div>
    );
};


export default MyModal