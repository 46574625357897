import React, { useEffect, useState } from "react";
import "./auth.css";
import { useDispatch, useSelector } from "react-redux";
import { displayToastWithTimeout, setIsLoading } from "../../store/slices/uiSlice";
import { delay } from "../Login";
import axios from "axios";
import { API } from "../../constants/constant";
import { RESET_AUTH, SET_CODE, SET_EMAIL } from "../../store/slices/authSlice";
import { useNavigate } from "react-router-dom";


const ConfirmAccount = () => {
    const nav = useNavigate()
    const dispatch = useDispatch()
    const auth = useSelector((s) => s.auth)


    const fData = {
        identifier: auth.email,
        code: "",
    }
    const [formData, setFormData] = useState(fData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCode = async (e) => {
        e.preventDefault()
        dispatch(setIsLoading(true))

        try {
            const response = await axios.post(`${API}/api/users/confirm-account`, formData);
            if (response.status === 200) {
                dispatch(RESET_AUTH())
                await delay(500)
                nav('/login')
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
            }

        } catch (error) {
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error setting status.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));
        } finally {
            dispatch(setIsLoading(false))
        }
    }


    useEffect(() => {
        if (!auth.email) {
            nav('/login', { replace: true })
        }
    }, [])

    return (
        <div className="forgotPass">

            <div className="content">
                <p className="message flex justify-center x-center column">
                    We've sent you a six digit code. <br />
                </p>

                <form onSubmit={handleCode}>
                    <div>
                        <input type="number" name="code" id="code" placeholder="Your code" required onChange={handleChange} />
                    </div>
                    <input type="submit" value="Validate" />
                </form>
            </div>
            <img className="svg" src={process.env.PUBLIC_URL + '/assets/forget-pass.svg'} alt="" />
        </div>
    )
}

export default ConfirmAccount

