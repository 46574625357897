import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './style.css'
import { useDispatch, useSelector } from 'react-redux';
import { setHasAppBar, setIsLoading } from '../../store/slices/uiSlice';
import { delay } from '../Login';

const Welcome = () => {
  const nav = useNavigate()
  const dis = useDispatch()
  const user = useSelector((s)=>s.user)

  const doDelay = async ()=>{
    dis(setIsLoading(true))
    dis(setHasAppBar(false))
    await delay(500);
    dis(setIsLoading(false))
  }

  const toLink=async(link)=>{
    dis(setIsLoading(true))
    await delay(500);
    nav(`/${link}`)
  }

  useEffect(()=>{
    doDelay()
  }, [user.token])

  const logo2 = process.env.PUBLIC_URL + './assets/logo.png'
  return (
    <div>
      <div className="flex x-center wrap">
        <div className="f1">
          <h2 className='welcome-text'>Welcome to</h2>
          <div className="logo flex column x-center">
            <h1>JUST</h1>
            <h2>Digital Diary</h2>
          </div>
        </div>
        <div className="f1 logoImage">
          <img src={logo2} alt="Logo" />
        </div>
      </div>

      <div style={{height: 50 + 'px'}}></div>
      {
        user && !user.token ? 
        <div className='flex column g20 wrap frontbtns x-center'>
          <button className='btn front' onClick={async()=>{
            dis(setIsLoading(true))
            await delay(500)
            nav('/login')
          }}>
            Login
          </button>
          <button className='btn front' onClick={async()=>{
            dis(setIsLoading(true))
            await delay(500)
            nav('/register')
          }}>
            Sign Up
          </button>

        </div>
        :
        <div className='bigBtns'>

          <div className="bigButton" onClick={()=>toLink('faculties')}>
            <img src={process.env.PUBLIC_URL + './assets/fm.svg'} alt="" />
            <p>Faculty <br />Members</p>
          </div>

          <div className="bigButton" onClick={()=>toLink('offices')}>
            <img src={process.env.PUBLIC_URL + './assets/adm.svg'} alt="" />
            <p>Administrative <br /> Offices</p>
          </div>

          <div className="bigButton">
            <img src={process.env.PUBLIC_URL + './assets/cal.svg'} alt="" />
            <p>Calender <br />Events</p>
          </div>

            <div className="bigButton" onClick={() => toLink('explore')}>
            <img src={process.env.PUBLIC_URL + './assets/exp.svg'} alt="" />
            <p>Explore <br /> Just</p>
          </div>

        </div>
      }
      
    </div>
  );
};

export default Welcome;