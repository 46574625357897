import React, { useEffect, useState } from "react";
import './header.css'
import { useDispatch, useSelector } from "react-redux";
import { logout, setInfo } from "../../store/slices/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { displayToastWithTimeout, setHasAppBar, setIsLoading } from "../../store/slices/uiSlice";
import { API } from "../../constants/constant";
import axios from "axios";
import { delay } from "../../pages/Login";
import { ClearApiData, fetchDesiredData } from "../../store/slices/dataSlice";

const TheDrawer = ()=>{

    const user = useSelector((s)=>s.user)
    const loc = useLocation()
    const dispatch = useDispatch()
    const nav = useNavigate()
    const apiUrl = `/api/users/me`
    const data = useSelector((state)=>state.dataSliceState.data[apiUrl])
    useEffect(() => {
        if(!user.token){
            dispatch(logout())
            dispatch(ClearApiData())
        } if (!data) {
            dispatch(fetchDesiredData({ apiUrl, token: user.token }));
          } else {
            const a = false
                  //   const desig = response.data.data.designations && response.data.data.designations ? response.data.data.designations.foreach((m)=> m.name) : null
                  const desig = null
                  const info = {
                      type: data.type,
                      isChairman: a,
                      dept_id: data.department && data.department.dept_id ? data.department.dept_id : null,
                      sub_office_id: data.sub_office && data.sub_office ? data.sub_office : null,
                      name: data.name,
                      username: data.username,
                      email: data.email,
                      designations: desig,
                      department: data.department && data.department.name ? data.department.name : null
                  }
                  dispatch(setInfo(info))
                  dispatch(setIsLoading(false))
        }


    
        
    
        dispatch(setIsLoading(false))
      }, [dispatch, apiUrl, data, user.token]);


    return user.role === "master-admin" || user.role === "admin" ?(
        <div className="TheDrawer flex column g20 space-between">
            <UserPart user={user}/>
            <button className="btn" onClick={()=>{
                        nav('/admin-panel')
                        dispatch(setHasAppBar(false))
                    }}>
                        Admin Panel
                    </button> 
                    <div className="flex column g10">
                    {
                        loc.pathname !== '/change-password' ? 
                        <button className="btn front" onClick={async () => {
                            dispatch(setIsLoading(true))
                            dispatch(setHasAppBar(false))
                            await delay(500)
                            nav('/change-password')
                        }}>
                            Change Password
                        </button>
                        : null
                    }
                    {
                        loc.pathname !== '/about' ? 
                        <button className="btn about" onClick={async()=>{
                            dispatch(setIsLoading(true))
                            dispatch(setHasAppBar(false))
                            await delay(500)
                            nav('/about')
                        }}>
                            About Us
                        </button>
                        : null
                    }
                    <button className="btn logout" onClick={()=>{
                        dispatch(logout())
                        dispatch(ClearApiData())
                        dispatch(setHasAppBar(false))
                        nav('/', {replace: true})
                    }}>
                        Logout
                    </button>
                </div>
        </div>
    ) : user.token ? (
        <div className="TheDrawer flex column space-between">

                <div className="flex column g20">
                    <UserPart user={user}/>
                
                </div>
                <div className="flex column g10">

                    <button className="btn front" onClick={async()=>{
                        dispatch(setIsLoading(true))
                        dispatch(setHasAppBar(false))
                        await delay(500)
                        nav('/about')
                    }}>
                        About
                    </button>
                    <button className="btn front" onClick={async () => {
                        dispatch(setIsLoading(true))
                        dispatch(setHasAppBar(false))
                        await delay(500)
                        nav('/change-password')
                    }}>
                        Change Password
                    </button>
                    <button className="btn logout" onClick={()=>{
                        dispatch(logout())
                        dispatch(setHasAppBar(false))
                        nav('/', {replace: true})
                    }}>
                        Logout
                    </button>
                </div>
            </div>
    ) : (
        <div style={{height: 100+'%', justifyContent: "center"}} className="flex column g20 x-center">
            <button className="btn front" onClick={async()=>{
                dispatch(setIsLoading(true))
                dispatch(setHasAppBar(false))
                await delay(500)
                nav('/login', {replace: true})
            }}>
                Log In
            </button>
            <button className="btn front" onClick={async()=>{
                dispatch(setIsLoading(true))
                dispatch(setHasAppBar(false))
                await delay(1000)
                nav('/register', {replace: true})
            }}>
                Sign Up
            </button>
        </div>
    )
}

export default TheDrawer


const UserPart = ({user})=>{

    useEffect(()=>{

    }, [user])
    return(
        <div className="info">
            <h2 className="name">
                {user.name}
            </h2>
            <p className="email">
                {user.email}
            </p>
            <p className="email">
                @{user.username}
            </p>
            
        </div>
    )
}