import axios from "axios";
import React, { useEffect, useState } from "react";
import { API } from "../../constants/constant";
import { useDispatch, useSelector } from "react-redux";
import { displayToastWithTimeout, setIsLoading } from "../../store/slices/uiSlice";
import { delay } from "../../pages/Login";
import MemberComponent from "./component/memberComp";
import UserComponent from "./component/userComp";
import AdminComponent from "./component/adminComp";
import MasterAdminComponent from "./component/masterAdminComp";

const AllUsers = ()=> {
    const dispatch = useDispatch()
    const user = useSelector((s)=> s.user)
    const [users, setUsers] = useState({})
    const [navLink, setNavLink] = useState([])
    const [sNav, setSNav] = useState(null);
    const [query, setQuery] = useState('');
    const [oneTwo, setOneTwo] = useState(1)

    const onSuccess = ()=>{
        fetchAllUsers()
    }



    const fetchAllUsers = async () => {

        dispatch(setIsLoading(true))

        try {
            const response = await axios.get(`${API}/api/get/all-users`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });


            setUsers(response.data.users)
            setNavLink(Object.entries(response.data.users).map(([key, value], i) => {
                if(!i){
                    setSNav(key)
                }
                return key
            }))

        } catch (error) {
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error getting faculty data.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

        } finally {
            dispatch(setIsLoading(false))
        }
    };

    useEffect(()=>{
        if(Object.keys(users).length < 1){
            fetchAllUsers()
        }
    }, [])

    return (
        <div style={{
            marginBottom: '20px',
        }}>

            <h1 className="head2 flex g20 space-between x-center exceptionHead" style={{
                margin: '20px 0',
                padding: '10px 20px'
            }}>ALL USERS
            <div className="flex x-center justify-center" style={{
                
            }}>
                <input style={{
                    borderRadius: '8px',
                    width: 'auto',
                    maxWidth: '200px',
                    minWidth: '80px',
                    height: '30px',
                    border: 'none',
                    padding: '0 10px'
                    }} placeholder="Search" type="text" onChange={e => setQuery(e.target.value)} value={query}/>
            </div>
            </h1>

            <div className="headNav" style={{
                position: 'sticky',
                top: '10px',
            }}>
                {
                    Array.isArray(navLink) && navLink.length > 0 ? (
                        <div className="flex g5 localNav">
                            {
                                navLink.map((d, i)=>{
                                    return (
                                        <div onClick={()=>setSNav(d)} key={d} className={"localNavLink " + (sNav === d ? 'active f1' : '')}>
                                            {d}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ) : (
                        <div></div>
                    )
                }
            </div>

            <div className="page">
                {
                    sNav === navLink[0] ? (
                        <MemberComponent data={users.members} onSuccess={onSuccess} query={query} oneTwo={oneTwo} setOneTwo={setOneTwo}/>
                    ) : sNav === navLink[1] ? (
                        <AdminComponent data={users.admins} onSuccess={onSuccess} />
                        ) : sNav === navLink[2] ? (
                            <MasterAdminComponent data={users.master_admins} onSuccess={onSuccess} />
                            ) : sNav === navLink[3] ? (
                                <UserComponent data={users.students} onSuccess={onSuccess} />
                            ) : null
                }
                
            </div>

        </div>
    )
}

export default AllUsers