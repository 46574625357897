import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setIsLoading, displayToastWithTimeout } from '../../store/slices/uiSlice';
import axios from 'axios';
import { API } from '../../constants/constant';
import { delay } from '../Login';
import './dept.css'
import { fetchDesiredData } from '../../store/slices/dataSlice';

const Department = () => {
  const { faculty_id } = useParams();
  const location = useLocation();
  const nav = useNavigate()
  const [deptData, setDeptData] = useState([])
  const [faculty, setFaculty] = useState('')
  const dispatch = useDispatch()
  const user = useSelector((s)=>s.user)
  const apiUrl = `/api/get/departments/${faculty_id}`
  const data = useSelector((state)=>state.dataSliceState.data[apiUrl])
  const loading = useSelector((state)=>state.dataSliceState.loading)
  const error = useSelector((state)=>state.dataSliceState.error)


  useEffect(() => {
    if (!data) {
      dispatch(fetchDesiredData({ apiUrl, token: user.token }));
    } else {
     
      setDeptData(data.departments)
      setFaculty(data.faculty_name)
    }
    dispatch(setIsLoading(false))

    const timeoutId = setTimeout(()=>dispatch(()=>fetchDesiredData({ apiUrl, token: user.token })), 5000);
    return () => clearTimeout(timeoutId);
  }, [dispatch, apiUrl, data, user.token]);

  const gotoLink = async (dept_id)=>{
    dispatch(setIsLoading(true))
    await delay(500);
    nav(`/faculty-members/${dept_id}`)
  }


  return (
    <div>
      <h2 className='pageHead'>All Departments</h2>
      <p className='subTitle'>Under</p>
      <h3 className='extraTitle'>{faculty && faculty}</h3>
      <div className="departments">
        {
            deptData && deptData.length > 0 ? deptData.map((m, i)=>{
                return (
                    <div key={i} className="dept" onClick={()=>gotoLink(`${m.dept_id}`)}>
                       <div className="flex x-center g10">
                        <p className="float left">
                                {m.shortname}
                            </p>
                            
                            <div className="flex column">
                                <p className="name">
                                    {m.name}
                                </p>
                                <div className="count">
                                    Total: {m.membersCount} Faculty Members
                                </div>
                            </div>
                        </div>
                        <p className="float right">
                            {m.dept_id}
                        </p>
                    </div>
                )
            }) : <h2 className='subTitle'>Sorry, Try Again!</h2>
        }
      </div>
    </div>
  );
};

export default Department;