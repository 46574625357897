import React, { useEffect, useState } from "react";
import "./auth.css";
import { useDispatch } from "react-redux";
import { displayToastWithTimeout, setIsLoading } from "../../store/slices/uiSlice";
import { delay } from "../Login";
import axios from "axios";
import { API } from "../../constants/constant";
import { RESET_AUTH, SET_CODE, SET_EMAIL } from "../../store/slices/authSlice";
import { useNavigate } from "react-router-dom";


const ForgotPassword = ()=> {
    const nav = useNavigate()
    const dispatch = useDispatch()
    const fData = {
        email: "",
    }
    const [formData, setFormData] = useState(fData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleEmail = async (e) => {
        e.preventDefault()
        dispatch(setIsLoading(true))

        try {
            const response = await axios.post(`${API}/api/users/forgot-password`, formData);

            dispatch(SET_EMAIL(formData.email))
            if (response.status === 200) {
                nav('/validate-code')
                await delay(500)
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
                
            }

        } catch (error) {
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error setting status.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));
        } finally {
            dispatch(setIsLoading(false))
        }
    }

    useEffect(()=>{

    }, [])

    return (
        <div className="forgotPass">
            
            <div className="content">
                <h1 className="h1">Forgot Password ?</h1>
                <form onSubmit={handleEmail}>
                    <div>
                        <input type="email" name="email" id="email" placeholder="Your Email" required onChange={handleChange}/>
                    </div>
                    <p className="exception">
                        We’ll send a verification code to this email
                    </p>
                    <input type="submit" value="Send" />
                </form>
            </div>
            <img className="svg" src={process.env.PUBLIC_URL + '/assets/forget-pass.svg'} alt="" />
        </div>
    )
}

export default ForgotPassword