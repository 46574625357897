// src/Register.jsx
import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as Loader from 'react-loader-spinner'
import { useDispatch } from 'react-redux';
import { displayToastWithTimeout, setIsLoading } from '../store/slices/uiSlice';
import { delay } from './Login';
import { API } from '../constants/constant';


const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    username: '',
    password: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const nav = useNavigate();
  const dispatch = useDispatch()

  const handleRegister = async (e) => {
    e.preventDefault();
        dispatch(setIsLoading(true))

        try {
        const response = await axios.post(`${API}/api/users/register`, formData);
          await delay(1000)
          dispatch(setIsLoading(false))
          dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
        nav('/login')

        } catch (error) {
        const errorMessage = error.response?.data?.message || 'Registration failed. Please try again.';

          dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));
        }
    };
    useEffect(()=>{
      dispatch(setIsLoading(false))
    }, [])

  return  (
    <div>
      <h2 className='pageHead'>Sign Up</h2>
      <form className='form' onSubmit={handleRegister}>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="text"
            id="email"
            placeholder='eg. youremail@just.edu.bd'
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            placeholder='eg. sabercse'
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="name">Full Name:</label>
          <input
            type="text"
            id="name"
            placeholder='eg. Saber Mahmud'
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            placeholder='Your Password'
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
      <input className='submit' type="submit" value="Register" />
      <p className="flex wrap x-center space-between g20">
        <p>
          Already Have an account?
        </p>
        <button className='btn loginhere' onClick={async()=>{
            dispatch(setIsLoading(true))
            await delay(500)
            nav('/login')
          }}>
            Login Here
          </button>
      </p>
    </form>
    </div>
  );
};

export default Register;