import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../constants/constant";
import axios from "axios";
import { displayToastWithTimeout, setIsLoading } from "../../store/slices/uiSlice";
import { delay } from "../../pages/Login";
import './style.css'
import AllUsers from "./allUsers";
import { useNavigate } from "react-router-dom";


const AddAFacultyMember = ()=>{
    const [designations, setDesignations] = useState(null);
    const nav = useNavigate()
    const user = useSelector((s)=> s.user)
    const [type, setType] = useState(1)
    const [load, setLoad] = useState(false)
    const loading = useSelector((s)=> s.ui.loading)
    const dispatch = useDispatch()
    const [alldept, setAlldept] = useState(null)
  const [offices, setOffices] = useState(null)
    const fData = {
        username: "",
        name: "",
        email: "",
        role_id: 2,
        password: "",
        type: type,
        phone: "",
        additional_email: '',
        achievement: "",
        room_no: "",
        designation_id: "",
        department: "",
        sub_office_id: "",
        present: 1,
      } 

      const handleTwinClick = (type) => {
        setType(type)
        setFormData({
          ...formData,
          type: type,
        });
      }

    const [formData, setFormData] = useState(fData);
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
  
      const handleSubmit = async (e) => {
        e.preventDefault();

        dispatch(setIsLoading(true))

        try {
          const response = await axios.post(`${API}/api/add/member`, formData, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          });
  
          if(response.status === 201){
            dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
            setFormData(fData)
            setLoad(true)
            await delay(2000)
            window.location.reload()
          } else {
            dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
          }
  
        } catch (error) {
            await delay(2000)
          const errorMessage = error.response?.data?.message || 'Error getting faculty data.';
          dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));
  
        } finally {
            
          
          dispatch(setIsLoading(false))
        }
      };
  
    useEffect(() => {

        const fetchFacultyData = async () => {
            
            dispatch(setIsLoading(true))
            
            try {
                const response = await axios.get(`${API}/api/get/all-designation`, {
                    headers: {
                      Authorization: `Bearer ${user.token}`,
                    },
                  });
              const response2 = await axios.get(`${API}/api/get/all-departments`, {
                headers: {
                  Authorization: `Bearer ${user.token}`,
                },
              });

              const response3 = await axios.get(`${API}/api/get/all-sub-offices`, {
                headers: {
                  Authorization: `Bearer ${user.token}`,
                },
              });

              setDesignations(response.data)
              setAlldept(response2.data.departments)
              setOffices(response3.data.subOffices)
              await delay(2000)
              dispatch(setIsLoading(false))
              
      
            } catch (error) {
              const errorMessage = error.response?.data?.message || 'Error getting faculty data.';
              dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));
      
            }
          };
      
          fetchFacultyData();
      
    }, [load]);
  

  
    return (
      <div className="AddADept">
      
        <div className="flex wrap g20">
        <form className="f4 fancyForm" onSubmit={handleSubmit} style={{
            background: type === 1 ? "#d0c6ff" : "#c9dab5",
        }}>
            <h2>Add A{type === 1 ? " Faculty" : "n Office"} Member</h2>

          <div className="select-type">
              <div onClick={() => handleTwinClick(1)} className={type === 1 ? "option active" : "option"}>Faculty Member</div>
              <div onClick={() => handleTwinClick(2)} className={type === 2 ? "option active" : "option"}>Office Member</div>
          </div>
            <div>
            <label htmlFor="name">Full Name</label>
            <input
                type="text"
                id="name"
                placeholder='eg. 01'
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
            />
            </div>
            <div>
            <label htmlFor="username">Username</label>
            <input
                type="text"
                id="username"
                placeholder='eg. Computer Science and Engineering'
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
            />
            </div>
            <div>
            <label htmlFor="email">Email</label>
            <input
                type="text"
                id="email"
                placeholder='eg. CSE'
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
            />
            </div>
            <div>
              <label htmlFor="additional_email">Additional Email</label>
              <input
                type="text"
                id="additional_email"
                placeholder='eg. CSE'
                name="additional_email"
                value={formData.additional_email}
                onChange={handleChange}
              />
            </div>
            <div>
            <label htmlFor="password">Password</label>
            <input
                type="password"
                id="password"
                placeholder='eg. CSE'
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
            />
            </div>
            <div>
                <label htmlFor="phone">Phone</label>
                <input
                    type="text"
                    id="phone"
                    placeholder='eg. CSE'
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="room_no">Room No</label>
                <input
                    type="text"
                    id="room_no"
                    placeholder='eg. CSE'
                    name="room_no"
                    value={formData.room_no}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="achievement">Achievement</label>
                <input
                    type="text"
                    id="achievement"
                    placeholder='eg. CSE'
                    name="achievement"
                    value={formData.achievement}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="designation_id">Designation</label>
                <select required id="designation_id" value={formData.designation_id} name="designation_id" onChange={handleChange}>
                    <option disabled value=''>Select Designation</option>
                    {
                        designations && designations.map((d)=>{
                            return( d.type === type ?
                                <option key={d.id} value={d.designation_id}>{d.name}</option> : null
                            )
                        })
                    }
                </select>
            </div>

            {
              type === 1 ? 
                <div>
                  <label htmlFor="present">On a Study Leave ?</label>
                  <select required id="present" value={formData.present} name="present" onChange={handleChange}>
                    <option value={1}>Yes</option>
                    <option value={2} >No</option>
                  </select>
                </div>
              :
                <div>
                  <label htmlFor="present">Is present ?</label>
                  <select required id="present" value={formData.present} name="present" onChange={handleChange}>
                    <option value={1} >Yes</option>
                    <option value={2}>No</option>
                  </select>
                </div>
            }

            {
              type === 1 ? 
              <div>
                  <label htmlFor="department">Department</label>
                  <select required id="department" value={formData.department} name="department" onChange={handleChange}>
                      <option disabled value=''>Select Department</option>
                      {
                          alldept && alldept.map((d, i)=>{
                              return(
                                  <option key={d.id} value={d.dept_id}>{`${i+1}. ${d.name} (${d.dept_id})`}</option>
                              )
                          })
                      }
                  </select>
              </div>
              :
                <div>
                  <label htmlFor="sub_office_id">Sub Office</label>
                  <select required id="sub_office_id" value={formData.sub_office_id} name="sub_office_id" onChange={handleChange}>
                    <option disabled value=''>Select Sub Office</option>
                    {
                      Array.isArray(offices) && offices.map((d, i) => {
                        return (
                          <option key={d.id} value={d.sub_office_id}>{`${i + 1}. ${d.name}`}</option>
                        )
                      })
                    }
                  </select>
                </div>
            }
            


            <input className='submit' type="submit" value={type === 1 ? "Add Faculty Member" : "Add Office Member"} />
         </form>
        </div>
        <div className="flex">
          <div className="bigBtns button" onClick={()=>{
            nav('/admin-panel/bulk-upload')
          }}>Bulk Upload Members</div>
        </div>

        <AllUsers />
        
      </div>
    );
}

export default AddAFacultyMember