import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { displayToastWithTimeout, setIsLoading } from '../../store/slices/uiSlice';
import axios from 'axios';
import { API } from '../../constants/constant';
import { delay } from '../Login';
import './style.css'
import { OtherMember } from '../facultyMembers/facMembers';
import { fetchDesiredData } from '../../store/slices/dataSlice';

const OfficeMembers = () => {
    const { sub_office_id } = useParams();
    const location = useLocation();
    const nav = useNavigate()
    const [officeMember, setOfficeMember] = useState(null)
    const [suboffice, setSubOffice] = useState(null)
    const dispatch = useDispatch()
    const user = useSelector((s) => s.user)
    const apiUrl = '/api/get/all-sub-offices'
    const apiUrl2 = '/api/get/office-members/' + sub_office_id
    const data = useSelector((state)=>state.dataSliceState.data[apiUrl])
    const data2 = useSelector((state)=>state.dataSliceState.data[apiUrl2])


    const [sortedMembers, setSortedMembers] = useState([]);
    const designationRank = {
        'Vice Chancellor': 1,
        'PS to Vice Chancellor': 2,
        'Registrar': 3,
        'Proctor': 4,
        'Registrar': 5,
        'Proctor': 6,
        'Treasurer': 7,
        'Director': 8,
        'Dean': 9,
        'Director (Additional Charge)': 10,
        'Deputy Registrar': 11,
        'Assistant Registrar': 12,
        'Assistant Proctor': 13,
        'Provost': 14,
        'Deputy Director': 15,
        'Chief Medical Officer': 16,
        'Senior Medical Officer': 17,
        'Deputy Director': 18,
        'Section Officer (Grade-9)': 19,
        'Section Officer (Grade-10)': 20,
    };


    const fetchFacultyMemberData = async () => {

    if (!data2) {
      dispatch(fetchDesiredData({ apiUrl: apiUrl2, token: user.token }));
    } else {
      letsSort(data2.officeMembers)
    }
  };

    const letsSort = async(data)=>{
    const theArray = [...data]
    const sorted = theArray.sort((a, b) => {
      const designationA = a.offices[0]?.designation || '';
      const designationB = b.offices[0]?.designation || '';

      const rankA = designationRank[designationA] || Infinity;
      const rankB = designationRank[designationB] || Infinity;

      return rankA - rankB;
    });

    setSortedMembers(sorted);
  }

    useEffect(() => {
    if (!data) {
      dispatch(fetchDesiredData({ apiUrl, token: user.token }));
    } else {
        
      const hasDept = data.subOffices.some((m)=>{
        return m.sub_office_id === sub_office_id
      })

      hasDept ? fetchFacultyMemberData() : nav('/')
      const fac = data.subOffices.filter((m)=>{
        return m.sub_office_id === sub_office_id
      })
      setSubOffice(fac[0])
    }

    dispatch(setIsLoading(false))
    const timeoutId = setTimeout(()=>{
      dispatch(fetchDesiredData({ apiUrl, token: user.token }))
      dispatch(fetchDesiredData({apiUrl: apiUrl2, token: user.token}))
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [dispatch, apiUrl, data, data2, apiUrl2, user.token]);



    // const fetchOfficeMemberData = async () => {

    //     dispatch(setIsLoading(true))

    //     try {
    //         const response = await axios.get(`${API}/api/get/office-members/${sub_office_id}`, {
    //             headers: {
    //                 Authorization: `Bearer ${user.token}`,
    //             },
    //         });
    //         letsSort(response.data.officeMembers)
    //         await delay(500)
    //         dispatch(setIsLoading(false))



    //     } catch (error) {
    //         const errorMessage = error.response?.data?.message || 'Error getting faculty data.';

    //         dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

    //     }
    // };

    // const fetcSubOfficeData = async () => {
    //     dispatch(setIsLoading(true))
    //     try {
    //         const response = await axios.get(`${API}/api/get/all-sub-offices`, {
    //             headers: {
    //                 Authorization: `Bearer ${user.token}`,
    //             },
    //         });

    //         const hasSubOffice = response.data.subOffices.some((m) => {
    //             return m.sub_office_id === sub_office_id
    //         })

    //         hasSubOffice ? fetchOfficeMemberData() : nav('/')
    //         const sub_off = response.data.subOffices.filter((m) => {
    //             return m.sub_office_id === sub_office_id
    //         })

    //         setSubOffice(sub_off[0])

    //     } catch (error) {
    //         const errorMessage = error.response?.data?.message || 'Error getting faculty data.';

    //         dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));

    //     } finally {
    //         await delay(500)
    //         dispatch(setIsLoading(false))
    //     }
    // };

    const [selectedItemIndex, setSelectedItemIndex] = useState(0);

    const handleItemClick = (index) => {
        setSelectedItemIndex(index === selectedItemIndex ? null : index);
    };

    // useEffect(() => {
    //     fetcSubOfficeData()
    // }, [])

    return (
        <div>

            <h2 className='extraTitle dept-text'>{suboffice && suboffice.name}</h2>
            <div className="departments">
                {
                    sortedMembers && sortedMembers.map((m, i) => {
                        return <OtherMember key={i} index={i + 1} handleItemClick={handleItemClick} data={m} isSelected={(i + 1) === selectedItemIndex} />
                    })
                }
            </div>
        </div>
    );
}

export default OfficeMembers