import React, { useEffect, useState } from "react";
import "./auth.css";
import { useDispatch, useSelector } from "react-redux";
import { displayToastWithTimeout, setIsLoading } from "../../store/slices/uiSlice";
import { delay } from "../Login";
import axios from "axios";
import { API } from "../../constants/constant";
import { SET_CODE, SET_EMAIL } from "../../store/slices/authSlice";
import { useNavigate } from "react-router-dom";


const ValidateCode = () => {
    const nav = useNavigate()
    const dispatch = useDispatch()
    const auth = useSelector((s)=>s.auth)
    const [attempt, setAttempt] = useState(1)
    const [hasTime, setHasTime] = useState(true);

    const onTimerEnd = ()=>{
        setHasTime(false)
    }

    const fData = {
        email: auth.email,
        code: "",
    }
    const [formData, setFormData] = useState(fData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const resendCode = async (e) => {
        e.preventDefault()
        dispatch(setIsLoading(true))

        try {
            const response = await axios.post(`${API}/api/users/forgot-password`, {email: auth.email});
            if (response.status === 200) {
                setHasTime(true)
                setAttempt((a)=>a+1)
                await delay(500)
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
            }

        } catch (error) {
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error setting status.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));
        } finally {
            dispatch(setIsLoading(false))
        }
    }

    const handleCode = async (e) => {
        e.preventDefault()
        dispatch(setIsLoading(true))

        try {
            const response = await axios.post(`${API}/api/users/validate-code`, formData);

            if (response.status === 203) {
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
            } else if (response.status === 200) {
                dispatch(SET_CODE(formData.code))
                
                await delay(500)
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
                nav('/set-new-password', {replace: true})
            }

        } catch (error) {
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error setting status.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));
        } finally {
            dispatch(setIsLoading(false))
        }
    }

    useEffect(() => {
        if(!auth.email){
            nav('/forgot-password', {replace: true})
        }
    }, [attempt])

    return (
        <div className="forgotPass">

            <div className="content">
                <p className="message flex justify-center x-center column">
                    We've sent you a six digit code. <br />
                    <p>Resend In</p>
                     <div className="flex g20 justify-center x-center pd-y-10">
                        <TimerComponent onTimerEnd={onTimerEnd} />
                        <button className="resend" disabled={hasTime} onClick={resendCode}>Resend</button>
                     </div>
                </p>

                <form onSubmit={handleCode}>
                    <div>
                        <input type="number" name="code" id="code" placeholder="Your code" required onChange={handleChange} />
                    </div>
                    <input type="submit" value="Validate" />
                </form>
            </div>
            <img className="svg" src={process.env.PUBLIC_URL + '/assets/forget-pass.svg'} alt="" />
        </div>
    )
}

export default ValidateCode


const TimerComponent = ({ onTimerEnd }) => {
    const [secondsRemaining, setSecondsRemaining] = useState(59);

    useEffect(() => {
        if (secondsRemaining > 0) {
            const timerId = setTimeout(() => {
                setSecondsRemaining(secondsRemaining - 1);
            }, 1000);

            // Clean up the timeout on component unmount or if secondsRemaining changes
            return () => clearTimeout(timerId);
        } else {
            onTimerEnd();
        }
    }, [secondsRemaining, onTimerEnd]);

    return (
        <span style={{
            fontSize: '20px',
            fontWeight: '900'
        }}>
            0 m: {secondsRemaining} s
        </span>
    );
};