import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {persistStore, persistReducer} from 'redux-persist'
import storage from "redux-persist/lib/storage";
import userSlice from "./slices/userSlice";
import uiSlice from "./slices/uiSlice";
import { thunk } from "redux-thunk";
import authSlice from "./slices/authSlice";
import dataSlice from "./slices/dataSlice";


const persistConfig = {
    key: 'root',
    storage,
  }

  const rootReducer = combineReducers({
    user: userSlice,
    ui: uiSlice,
    auth: authSlice,
    dataSliceState: dataSlice
  })

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
  })

  export const persistor = persistStore(store);

  export default store