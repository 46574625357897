
import './style.css'

const MasterAdminComponent = ({ data, onSuccess }) => {
    console.log(data);
    return (
        <div>
            <h1>MasterAdmin Comp</h1>
        </div>
    )
}

export default MasterAdminComponent