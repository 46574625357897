import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Link, Navigate, useParams } from "react-router-dom";
import Faculties from "../Faculties";
import AddADepartment from "./AddADepartment";
import AddAFacultyMember from "./AddFacultyMember";
import { setIsLoading } from "../../store/slices/uiSlice";
import { delay } from "../../pages/Login";
import AddAnSubOffice from "./AddAnSubOffice";
import AddAnOffice from "./AddAnOffice";
import AddADesignation from "./AddADesignation";
import AddAFaculty from "./AddAFaculty";
import ManageGallery from "./ManageGallery";
import OtherPage from "./Others";
import SingleUser from "./component/manageSingleUser";
import BulkUploadMember from "./component/bulkUpload";

const AdminPanel = ()=>{
    const user = useSelector((s)=> s.user)
    const cUrl = useParams()

    const [routes, setRoutes]= useState([
        {
            link: "addFaculty",
            title: "Faculty",
            comp: <AddAFaculty />
        },
        {
            link: "addDept",
            title: "Department",
            comp: <AddADepartment />
        },
        {
            link: "addFacultyMember",
            title: "Member",
            comp: <AddAFacultyMember />
        },
        {
            link: "addAnOffice",
            title: "Office",
            comp: <AddAnOffice />
        },
        {
            link: "addAnSubOffice",
            title: "Sub Office",
            comp: <AddAnSubOffice />
        },
        {
            link: "addADesignation",
            title: "Designation",
            comp: <AddADesignation />
        },
        {
            link: "manageGallery",
            title: "Gallery",
            comp: <ManageGallery />
        },
        {
            link: "others",
            title: "Others",
            comp: <OtherPage />
        }
    ])

    useEffect(()=>{
    }, [])


    return user.role === "master-admin" || user.role === "admin" || user.role === "editor" ?(
        <div>
            <h1 className="pageHead">Admin Panel</h1>

            <div className="g10 parent">
                {
                    routes.map((r,i)=>{
                        return (
                            <Link key={i} to={r.link} className={`btn admin capitalize flex justify-center x-center div${i+1} ` + (r.link === cUrl["*"] ? "active" : 'a' )}>
                                {r.title}
                            </Link>
                        )
                    })
                }
            </div>

            <Routes>
                <Route path="/" element={<Navigate to={routes[0].link} replace />} />
                {
                    routes.map((r)=>{
                        return (
                            <Route key={r.link} path={r.link} element={r.comp}/>
                        )
                    })
                }
                <Route path='/bulk-upload' element={<BulkUploadMember />} />
                <Route path="/single-user/:uidd" element={<SingleUser uiddd={null} />} />
                <Route path="*" element={<h1>Invalid Url</h1>} /> {/* Handles invalid routes */}
            </Routes>

        </div>
    ) : (
        <div>
            <h1>Restricted</h1>
        </div>
    )
}

export default AdminPanel