import React, { useEffect, useRef, useState } from "react"; 
import { useDispatch, useSelector } from "react-redux";
import { displayToastWithTimeout, setIsLoading } from "../../store/slices/uiSlice";
import axios from "axios";
import { API } from "../../constants/constant";
import "./carousel.css";

const ExploreJUST = ()=> {

    const user = useSelector((s) => s.user)
    const dispatch = useDispatch()
    const [images, setImages] = useState([])
    const fetchGalleryImage = async () => {
        dispatch(setIsLoading(true))
        try {

            const response2 = await axios.get(`${API}/api/gallery/images`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            dispatch(setIsLoading(false))
            setImages(response2.data.images.filter((d) => d.isSelected && d.isSelected === 1 ? d : false)
                .sort((a, b) => a.priority - b.priority));

        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Error getting gallery data.';
            dispatch(displayToastWithTimeout({ type: 'success', message: errorMessage, stayFor: 4000 }));

        }
    }
    useEffect(()=>{
        fetchGalleryImage()
    }, [])

    return (
        <div className="flex column" style={{maxHeight: '80vh'}}>
            <h1 style={{
                textAlign: "center",
                padding: '20px 0',
                paddingTop: '5px',
                color: '#7f64b9'
            }} className="textCenter">
                Gallery
            </h1>
            <div className="carouselCOntainer">
                {images.length > 0 ? <CarouselComp images={images} /> : <p>Loading...</p>}
            </div>
        </div>
    )
}

export default ExploreJUST



const CarouselComp = ({ images }) => {
  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);
  const [currPos, setCurrPos] = useState(0);

  useEffect(() => {
    if (parentRef.current) {
      setParentWidth(parentRef.current.offsetWidth);
    }
    
  }, [images]);

  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const nextClick = () => {
    setCurrPos(prevPos => {
      const newPos = prevPos + parentWidth;
      if (newPos >= parentWidth * (images.length)) {
        setTimeout(() => setCurrPos(0), 0); // Reset position after transition
      }
      return newPos;
    });
  };

  const prevClick = () => {
    setCurrPos(prevPos => {
      let newPos = prevPos - parentWidth;
      if (newPos < 0) {
        newPos= parentWidth * (images.length-1)
      }
      return newPos;
    });
  };

  return (
    <div className="slider-frame" ref={parentRef}>
      <div className="arrPrev" onClick={prevClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M18 2L7 12L18 22" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div className="arrNext" onClick={nextClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M7 2L18 12L7 22" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      {
        parentWidth && <div
        className="slider"
        style={{
          transform: `translate3d(-${currPos}px, 0px, 0px)`,
          transition: 'transform 0.5s ease-in-out'
        }}
      >
        {images && images.map((d, i) => {
            return (
          <div
            style={{ width: parentWidth + 'px' }}
            className="slider-item"
            key={i}
          >
            {d.image && <img src={`${API}/uploads/${d.image}`} alt="Wow" />}
            {d.caption && (
              <div className="legend">
                {d.caption}
              </div>
            )}
          </div>
        )})}
      </div>
      }
    </div>
  );
};


