import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../constants/constant";
import axios from "axios";
import { displayToastWithTimeout, setIsLoading } from "../../store/slices/uiSlice";
import { delay } from "../../pages/Login";

const OtherPage = ()=> {

    useEffect(()=>{

    }, [])

    return (
        <div>
            <EditorComponent />
        </div>
    )

}


export default OtherPage


const EditorComponent = ()=> {
    const [editor, setEditor] = useState([])
    const user = useSelector((s) => s.user)
    const dispatch = useDispatch()
    const fData = {
        username: '',
        name: '',
        email: '',
        password: '',
    }
    const [formData, setFormData] = useState(fData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const createNewEditor = async (e) => {
        e.preventDefault();

        if (!formData.name || !formData.username || !formData.email || !formData.password) {
            dispatch(displayToastWithTimeout({ type: 'error', message: "All fields are required", stayFor: 4000 }));
            return
        }

        dispatch(setIsLoading(true))

        try {
            const response = await axios.post(`${API}/api/add/editor`, formData, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            if (response.status === 201) {
                setFormData(fData)
                await delay(500)
                fetchEditorData()
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));

            } else if (response.status === 203) {
                dispatch(displayToastWithTimeout({ type: 'error', message: response.data.message, stayFor: 4000 }));
            } else {
                dispatch(displayToastWithTimeout({ type: 'error', message: response.data.message, stayFor: 4000 }));
            }

        } catch (error) {
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error adding new Editor.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));
        } finally {
            dispatch(setIsLoading(false))
        }
    };

    const fetchEditorData = async () => {

        dispatch(setIsLoading(true))
        try {

            const response = await axios.get(`${API}/api/get/all-editor`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            await delay(500)
            dispatch(setIsLoading(false))
            setEditor(response.data);

        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Error getting Editors data.';
            dispatch(displayToastWithTimeout({ type: 'success', message: errorMessage, stayFor: 4000 }));

        }
    };

    const handleEditorStatus = async (username, to) => {
        const link = to === 'ac' ? '/api/set/editor-active/' : '/api/set/editor-deactive/'
        dispatch(setIsLoading(true))

        try {
            const response = await axios.post(`${API}${link}${username}`, {}, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            if (response.status === 201 || response.status === 200) {
                await delay(500)
                fetchEditorData()
                dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));

            } else if (response.status === 404) {
                dispatch(displayToastWithTimeout({ type: 'error', message: response.data.message, stayFor: 4000 }));
            } else {
                dispatch(displayToastWithTimeout({ type: 'error', message: response.data.message, stayFor: 4000 }));
            }

        } catch (error) {
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error setting status.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));
        } finally {
            dispatch(setIsLoading(false))
        }
    }


    useEffect(() => {
            fetchEditorData()
        
    }, [])

    return (
        <div className="AddADept">
            <div className="flex wrap g20">
                <form onSubmit={createNewEditor} className="fancyForm f1">
                    <h2>Add An Editor</h2>
                    <div>
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            placeholder='eg. Samsu'
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            placeholder='eg. 190100'
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            placeholder='eg. abul@gmail.com'
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            placeholder='eg. abul@gmail.com'
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <input className='submit' type="submit" value="Add An Editor" />
                </form>

                <div className="f1">
                    <div className="head2">All Editors</div>
                    {
                        Array.isArray(editor) ? editor.length > 0 ?
                            (<div className="editorcards">
                                {
                                    editor.toReversed().map((user) => {
                                        return (
                                            <div key={user.id} className="editorcard">
                                                <h1 className="name">{user.name}</h1>
                                                <div className="corner">{user.active === 1 ? "Active" : 'In-active'}</div>
                                                <div className="flex g10 x-center space-between">
                                                    <div>
                                                        <p className="username">@{user.username}</p>
                                                        <p className="email">{user.email}</p>
                                                    </div>
                                                    {
                                                        user.active === 1 ? (
                                                            <div onClick={() => handleEditorStatus(user.username, 'de')} className="bt d">
                                                                De-activate
                                                            </div>
                                                        ) : (
                                                            <div onClick={()=>handleEditorStatus(user.username, 'ac')} className="bt a">
                                                                Activate
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>) : (
                                <h3 style={{padding: '10px 0', letterSpacing: '2px'}}>There are no editors</h3>
                            )
                            : "Loading..."
                    }
                </div>
            </div>
        </div>
    )

}