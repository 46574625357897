import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setIsLoading, displayToastWithTimeout } from '../../store/slices/uiSlice';
import axios from 'axios';
import { API } from '../../constants/constant';
import { delay } from '../Login';
import './auth.css'
import { logout } from '../../store/slices/userSlice';

const ChangePassword = () => {
    const nav = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector((s) => s.user)
    const [currentPass, setCurrentPass] = useState(null)
    const [pass, setPass] = useState(null)
    const [confirmPass, setConfirmPass] = useState(null)
    const [passwordValidations, setPasswordValidations] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        numeric: false,
    });

    const validatePassword = (password) => {
        const length = password.length >= 6;
        const uppercase = /[A-Z]/.test(password);
        const lowercase = /[a-z]/.test(password);
        const numeric = /[0-9]/.test(password);

        setPasswordValidations({
            length,
            uppercase,
            lowercase,
            numeric,
        });
    };

    const handlePasswordSubmit = async (e) => {

        e.preventDefault();

        if (!passwordValidations.length || !passwordValidations.uppercase || !passwordValidations.lowercase || !passwordValidations.numeric) {
            return dispatch(displayToastWithTimeout({ type: 'error', message: "Password does not meet all requirements", stayFor: 4000 }));
        }

        if (!(pass === confirmPass)) {
            return dispatch(displayToastWithTimeout({ type: 'error', message: "Password not matched", stayFor: 4000 }));
        }
        dispatch(setIsLoading(true))

        try {
            const formData = {
                currentPassword: currentPass,
                newPassword: pass,
            }
            const response = await axios.post(`${API}/api/users/change-password`, formData, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            if (response.status === 200) {
                dispatch(logout())
                await delay(500)
                dispatch(displayToastWithTimeout({ type: 'success', message: 'Password Changed Successfully, Login Again', stayFor: 4000 }));
                nav('/login')
            }

        } catch (error) {
            await delay(500)
            const errorMessage = error.response?.data?.message || 'Error Changing Password.';
            dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));
        } finally {
            dispatch(setIsLoading(false))
        }
    }

    const gotoLink = async (dept_id) => {
        dispatch(setIsLoading(true))
        await delay(500);
        nav(`/faculty-members/${dept_id}`)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "password") {
            setPass(value);
            validatePassword(value);
        } else if (name === "confirmpassword") {
            setConfirmPass(value);
        } else if (name === "currentPassword") {
            setCurrentPass(value);
        }
    };

    useEffect(() => {
        dispatch(setIsLoading(false))
    }, [])

    return (
        <div>
            <h2 className='pageHead'>Change Your Password</h2>
            <div className="forgotPass">

                <div className="content">
                    <h1 className="h1">Set New Password</h1>
                    <form className="flex g20 column" onSubmit={handlePasswordSubmit}>
                        <div>
                            <input type="password" name="currentPassword" id="currentPassword" placeholder="Current Password" required onChange={handleChange} />
                        </div>
                        <div>
                            <input type="password" name="password" id="password" placeholder="Password" required onChange={handleChange} />
                        </div>
                        <div>
                            <input type="password" name="confirmpassword" id="confirmpassword" placeholder="Confirm Password" required onChange={handleChange} />
                        </div>
                        <div className="exceptions">
                            <p className={`exception ${passwordValidations.length ? 'hidden' : ''}`}>
                                Password should be at least 6 characters long
                            </p>
                            <p className={`exception ${passwordValidations.uppercase ? 'hidden' : ''}`}>
                                At least 1 uppercase letter
                            </p>
                            <p className={`exception ${passwordValidations.lowercase ? 'hidden' : ''}`}>
                                At least 1 lowercase letter
                            </p>
                            <p className={`exception ${passwordValidations.numeric ? 'hidden' : ''}`}>
                                At least 1 number or digit
                            </p>
                        </div>
                        <input type="submit" value="Save Password" />
                    </form>
                </div>
                <img className="svg" src={process.env.PUBLIC_URL + '/assets/forget-pass.svg'} alt="" />
            </div>
        </div>
    );
};

export default ChangePassword;