import './App.css';
import React, {useEffect, useState} from 'react';
import { Router, Routes, Route, BrowserRouter, Navigate, Outlet, useLocation } from 'react-router-dom';
import Login, { delay } from './pages/Login';
import Register from './pages/Register';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ActualHeader from './components/nav/header';
import Welcome from './pages/welcome/welcome';
import { setHasAppBar, setIsLoading } from './store/slices/uiSlice';
import AdminPanel from './components/AdminPanel/adminPanel';
import Department from './pages/department/department';
import FacultyMembers from './pages/facultyMembers/facMembers';
import AboutPage from './pages/about/about';
import Offices from './pages/administrative/offices';
import SubOffices from './pages/administrative/suboffices';
import Faculties from './components/Faculties';
import OfficeMembers from './pages/administrative/officeMembers';
import ExploreJUST from './components/explore/explore';
import ForgotPassword from './pages/Auth/forgotPassword';
import ValidateCode from './pages/Auth/validateCode';
import SetNewPassword from './pages/Auth/setNewPassword';
import ChangePassword from './pages/Auth/changePassword';
import ConfirmAccount from './pages/Auth/confirmAccount';

function App() {

  
  const isLoading = useSelector((s)=>s.ui.isLoading)
  const isDrawer = useSelector((s)=>s.ui.hasAppBar)
  const dispatch = useDispatch()

  const doDelay = async ()=>{
    dispatch(setIsLoading(true))
    dispatch(setHasAppBar(false))
    await delay(500);
    dispatch(setIsLoading(false))
  }

  useEffect(()=>{
    doDelay()
  }, [])

  return (
    <BrowserRouter>
      <div id="main">
          <div id="loader" className={isLoading?"active":"notactive"}>
            <div className="spin"></div>
          </div>
          <div id="header">
            <ActualHeader />
          </div>
          <div id="toast">

          </div>

          <div id="container">
            
            <div id="body">
              <Routes>
                <Route path='/' exact element={<Welcome/>} />
                <Route path='/login' exact element={<Login/>} />
                <Route path='/register' element={<Register/>} />
                <Route path='/faculties' element={<Faculties/>} />
                <Route path='/admin-panel/*' element={<AdminPanel/>} />
                <Route path='/department/:faculty_id' element={<Department/>} />
                <Route path='/faculty-members/:dept_id' element={<FacultyMembers/>} />
                <Route path='/about' element={<AboutPage/>} />
                <Route path='/explore' element={<ExploreJUST />} />
                <Route path='/offices' element={<Offices/>} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/confirm-account' element={<ConfirmAccount />} />
                <Route path='/validate-code' element={<ValidateCode />} />
                <Route path='/set-new-password' element={<SetNewPassword />} />
                <Route path='/change-password' element={<ChangePassword />} />
                <Route path='/sub-offices/:office_id' element={<SubOffices/>} />
              <Route path='/office-members/:sub_office_id' element={<OfficeMembers />} />
              </Routes>
            </div>

            <p
              style={
                {
                  textAlign: 'center',
                  padding: '3px',
                  color: 'GrayText'
                }
              }
            >Copyright &copy; {new Date().getFullYear()} by <a href="/about#our-team">Our Team</a></p>
          </div>
        </div>
        
    </BrowserRouter>
  );
}

export default App;
