// features/dataSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../constants/constant';
import { displayToastWithTimeout } from './uiSlice';
import { useDispatch } from 'react-redux';


export const fetchDesiredData = createAsyncThunk(
  'data/fetchDesiredData',
  
  async ({apiUrl, token}, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await axios.get(API + apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const newData = response.data;
      const existingData = getState().dataSliceState.data[apiUrl];
      const hasChanged = JSON.stringify(newData) !== JSON.stringify(existingData);

      if (hasChanged) {
        return { apiUrl, data: newData };
      }

      return null;
      
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Error getting data.';
      useDispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }))
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
    data: {},
    loading: false,
    error: null,
  }

const dataSlice = createSlice({
  name: 'dataSliceState',
  initialState,
  reducers: {
    ClearApiData: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDesiredData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDesiredData.fulfilled, (state, action) => {
          if(action.payload){
            state.data[action.payload.apiUrl] = action.payload.data;
          }
          state.loading = false;
      })
      .addCase(fetchDesiredData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      });
  },
});

export const { ClearApiData } = dataSlice.actions;
export default dataSlice.reducer;
