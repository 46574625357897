import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


const initialState = {
  isLoading: false,
  hasAppBar: false,
  toasts: [],
};

export const displayToastWithTimeout = createAsyncThunk(
    'ui/displayToastWithTimeout',
        async ({ type, message, stayFor }, { dispatch }) => {
            const toastId = Date.now(); // Use the same id for dispatching and removing the toast
            dispatch(uiSlice.actions.displayToast({ id: toastId, type, message, stayFor }));
            dispatch(uiSlice.actions.setIsLoading(false))
            // Clear the toast after the specified time
            return new Promise((resolve) => {
            setTimeout(() => {
                dispatch(uiSlice.actions.removeToast({ toastId }));
                resolve();
            }, stayFor);
            });
        }
  );

  const createToastElement = (id, type, message) => {
    const toastContainer = document.getElementById('toast');
    if (toastContainer) {
      const toastElement = document.createElement('div');
      toastElement.id = `toast-${id}`;
      toastElement.className = `toast ${type}`;
      toastElement.innerText = message;
      toastContainer.appendChild(toastElement);
      
      setTimeout(() => {
        toastElement.classList.add('active')
      }, 300);
    }
  };

  const removeToastElement= (id)=>{
    const toastElement = document.getElementById(`toast-${id}`);
    toastElement.classList.add('remove')
        setTimeout(() => {
            if (toastElement) {
                toastElement.remove();
              }
        }, 500);
  }


const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
        state.isLoading = action.payload;
    },
    setHasAppBar: (state, action) => {
      state.hasAppBar = action.payload;
    },
    displayToast: (state, action) => {
        const { id, type, message } = action.payload;
  
        state.toasts = state.toasts || [];
  
        const newToast = {
          id,
          type,
          message,
        };
        state.toasts.push(newToast);
  
        // Create and append the toast element to the container
        createToastElement(id, type, message);
      },
      removeToast: (state, action) => {
        const { toastId } = action.payload;
        state.toasts = state.toasts.filter((toast) => toast.id !== toastId);
  
        // Remove the toast element from the container
        removeToastElement(toastId)
      },
  },
});

export const { setIsLoading, setHasAppBar, displayToast, removeToast } = uiSlice.actions;
export default uiSlice.reducer;