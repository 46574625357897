
import './style.css'

const AdminComponent = ({ data, onSuccess }) => {
    console.log(data);
    return (
        <div>
            <h1>Admin Comp</h1>
        </div>
    )
}

export default AdminComponent