import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../constants/constant";
import axios from "axios";
import { displayToastWithTimeout, setIsLoading } from "../../store/slices/uiSlice";
import { delay } from "../../pages/Login";
import './style.css'
import MyModal from "../modal/mymodal";

const AddADepartment = ()=>{
    const [facultyData, setfacultyData] = useState(null);
    const user = useSelector((s)=> s.user)
    const [load, setLoad] = useState(false)
    const loading = useSelector((s)=> s.ui.loading)
    const dispatch = useDispatch()
    const [alldept, setAlldept] = useState(null)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModal = (content) => {
    setIsModalOpen(true);
    setModalContent(content);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

    const fData = {
        dept_id: "",
        name: "",
        shortname: "",
        faculty_id: "",
      }

    const [formData, setFormData] = useState(fData);
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
  
      const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(setIsLoading(true))

        try {
          const response = await axios.post(`${API}/api/add/department`, formData, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          });
  
          if(response.status === 201){
            dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
            fetchFacultyData()
            setFormData(fData)
            setLoad(true)
            await delay(500)
          } else {
            dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
          }
  
        } catch (error) {
            await delay(500)
          const errorMessage = error.response?.data?.message || 'Error getting faculty data.';
          dispatch(displayToastWithTimeout({ type: 'success', message: errorMessage, stayFor: 4000 }));

  
        } finally {
            
          
          dispatch(setIsLoading(false))
        }
      };

      const updateFaculty = async () => {
        try {
          const response = await axios.get(`${API}/api/get/faculties`, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          });
          setfacultyData(response.data);
        } catch(error) {
          const errorMessage = error.response?.data?.message || 'Error getting faculty data.';

          dispatch(displayToastWithTimeout({ type: 'success', message: errorMessage, stayFor: 4000 }));
        }
      }
  const fetchFacultyData = async () => {

    dispatch(setIsLoading(true))
    updateFaculty()
    try {

      const response2 = await axios.get(`${API}/api/get/all-departments`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      await delay(500)
      dispatch(setIsLoading(false))
      setAlldept(response2.data.departments)


    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Error getting faculty data.';

      dispatch(displayToastWithTimeout({ type: 'success', message: errorMessage, stayFor: 4000 }));

    }
  };

  const reloadOnSuccess = () => {
    fetchFacultyData();
    closeModal()
  };
  
    useEffect(() => {

        
      
          fetchFacultyData();
      
    }, [load]);
  

  
    return (
      <div className="AddADept">
        <MyModal isOpen={isModalOpen} close={closeModal}>
          {modalContent}
        </MyModal>
        <div className="flex wrap g20">
            <form className="f4 fancyForm" onSubmit={handleSubmit}>
            <h2>Add A Department</h2>
            <div>
            <label htmlFor="dept_id">Department Id:</label>
            <input
                type="text"
                id="dept_id"
                placeholder='eg. 01'
                name="dept_id"
                value={formData.dept_id}
                onChange={handleChange}
                required
            />
            </div>
            <div>
            <label htmlFor="name">Department Name:</label>
            <input
                type="text"
                id="name"
                placeholder='eg. Computer Science and Engineering'
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
            />
            </div>
            <div>
            <label htmlFor="shortname">Department Short Name:</label>
            <input
                type="text"
                id="shortname"
                placeholder='eg. CSE'
                name="shortname"
                value={formData.shortname}
                onChange={handleChange}
                required
            />
            </div>
            <div>
                <label htmlFor="">Faculty</label>
                <select value={formData.faculty_id} required name="faculty_id" onChange={handleChange}>
                    <option disabled value=''>Select Faculty</option>
                    {
                        facultyData && facultyData.map((d)=>{
                            return(
                                <option key={d.id} value={d.faculty_id}>{d.name}</option>
                            )
                        })
                    }
                </select>
            </div>

            <input className='submit' type="submit" value="Add A Department" />
         </form>
         <div className="f3">
            <h2 className="head2">Recent Departments</h2>
            {
                alldept && alldept ? 
                <div className="deptcards">
                    {
                        alldept.toReversed().map((d)=>{
                            return(
                                <div key={d.id} className="deptcard ctrl">
                                    <div className="flex g20 space-between x-center deptid">
                                        <h1>{d.shortname}</h1>
                                        <h3 >Dept Id: {d.dept_id}</h3>
                                    </div>
                                    <p>{d.name}</p>
                                <div onClick={() => { openModal(<EditTheData data={d} onSuccess={reloadOnSuccess} />) }} className="edit">
                                  <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M16.04 3.02001L8.16 10.9C7.86 11.2 7.56 11.79 7.5 12.22L7.07 15.23C6.91 16.32 7.68 17.08 8.77 16.93L11.78 16.5C12.2 16.44 12.79 16.14 13.1 15.84L20.98 7.96001C22.34 6.60001 22.98 5.02001 20.98 3.02001C18.98 1.02001 17.4 1.66001 16.04 3.02001Z" stroke="#ffffff" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M14.91 4.1499C15.58 6.5399 17.45 8.4099 19.85 9.0899" stroke="#ffffff" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                                  <p>Edit</p>
                                </div>
                                <div onClick={() => { openModal(<DeleteTheData data={d} onSuccess={reloadOnSuccess} />) }} className="delete">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="16px" height="16px" viewBox="0 0 24 24"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"></path></g></svg>
                                  <p>Delete</p>
                                </div>
                                </div>
                            )
                        })
                    }
                </div>
                : "Loading..."
            }
         </div>
        </div>
      </div>
    );
}

export default AddADepartment


const EditTheData = ({ data, onSuccess }) => {

  const user = useSelector((s) => s.user)
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({ name: data.name, shortname: data.shortname });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    dispatch(setIsLoading(true))
    try {
      const response = await axios.put(`${API}/api/edit/department/${data.dept_id}`, formData, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.status === 200) {
        await delay(500)
        dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
        onSuccess()
      } else {
        dispatch(displayToastWithTimeout({ type: 'error', message: response.data.message, stayFor: 4000 }));
      }

    } catch (error) {
      await delay(500)
      const errorMessage = error || 'Error deleting Office data.';
      dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  useEffect(() => {

  }, [])

  return (
    <div className="deleteOffice">
      <h2 className="heading">Update Office with Office Id: {data.office_id}</h2>
      <form className="f4 fancyForm" onSubmit={handleUpdate}>
        <div>
          <label htmlFor="name">Department Name</label>
          <input
            type="text"
            id="name"
            placeholder='eg. Computer Science and Engineering'
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="shortname">Department Short Name</label>
          <input
            type="text"
            id="shortname"
            placeholder='eg.CSE'
            name="shortname"
            value={formData.shortname}
            onChange={handleChange}
            required
          />
        </div>

        <input className='submit' type="submit" value="Update" />
      </form>
    </div>
  )
}

const DeleteTheData = ({ data, onSuccess }) => {

  const user = useSelector((s) => s.user)
  const dispatch = useDispatch()

  const handleDelete = async (e) => {
    e.preventDefault();
    dispatch(setIsLoading(true))
    try {
      const response = await axios.delete(`${API}/api/delete/department/${data.dept_id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.status === 200) {
        await delay(500)
        dispatch(displayToastWithTimeout({ type: 'success', message: response.data.message, stayFor: 4000 }));
        onSuccess()
      } else {
        dispatch(displayToastWithTimeout({ type: 'error', message: response.data.message, stayFor: 4000 }));
      }

    } catch (error) {
      await delay(500)
      const errorMessage = error || 'Error deleting Office data.';
      dispatch(displayToastWithTimeout({ type: 'error', message: errorMessage, stayFor: 4000 }));


    } finally {


      dispatch(setIsLoading(false))
    }
  }

  useEffect(() => {

  }, [])

  return (
    <div className="deleteOffice">
      <h2>Delete this Sub Office :</h2>
      <p>Department Name: <b>{data.name}</b></p>
      <p>Department Short Name: <b>{data.shortname}</b></p>
      <p>Department ID: <b>{data.dept_id}</b></p>
      <input onClick={handleDelete} className='submit' type="submit" value="Confirm Delete" />
    </div>
  )
}